import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';// eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import {copiriPortalRequest,copiriPortalRequestWithoutToken,sessionLogout} from '../api/api';// eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif';
import { toast } from 'react-toastify';
const MINUTE_MS = 100;
const GrandLodgeRecognition = (props) => {// eslint-disable-next-line
  const [uid,setUid] = useState([]);
  const [recog,setRecog] = useState('');
  const [recogList,setRecogList] = useState([]);
  const [selectedvalue,setSelectedvalue] = useState('')
  useEffect(() => {
    if (props.headerValue) {
      setUid(props.headerValue);
      if (props.recognitionListData.length > 0 ) {
        setRecogList(props.recognitionListData);
      }
    }
    const interval = setInterval(() => {
      changeOrgDropDown();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  // eslint-disable-next-line
  }, [props]);

  function changeOrgDropDown() {
    var checkDropDown = localStorage.getItem('changeDropDown');
    if (checkDropDown && checkDropDown==='true') {
      setSelectedvalue('');
      setRecog('');
    }
  }
  
  const handleOnChange = (e, type) => {
    if (e.target.value !=='') {
      setSelectedvalue(e.target.value);
      setRecog('');
      document.getElementById('showSpinner').style.display= 'block';
      //setRecog(<img className="rounded-circle avatar-xl" src={loader_image} alt="img" width="100px" />);
      copiriPortalRequestWithoutToken(config.elol_api_url + '/grandlodges/'+uid+'/recognition/'+e.target.value+'', config.app_version, 'get').then(response => {
        document.getElementById('showSpinner').style.display= 'none';
        
        if (response.status === 401) {
          toast.error('Your session has expired. Please log in again.');
          
          sessionLogout();
        }
        
        setRecog(response.data.status)
      }).catch(error=>{
        document.getElementById('showSpinner').style.display= 'none';
        
        if (error.response.status === 401) {
          toast.error('Your session has expired. Please log in again.');
          
          sessionLogout();
        }
      });
    }
  }
  
  function getItemName(name) {
    if (name.length > 100) {
      return name.substr(0, 100) + '...';
    } else {
      return name;  
    }
  }
  
  return (
    <>
      <Row className="align-items-center">
        <Col lg={10}>
          <div className="breadcrumb-flex" style={{margin:'0px'}}>
            <div className="bredcrumb1">
              <span className="SpanText">Select a Grand Lodge to Check Recognition:</span>
              <select className="form-control select-topnav admin_dropdown" name="admin_dropdown"  onChange={(e) => handleOnChange(e, 'country')} value={selectedvalue}>
                <option value="">Select</option>
                {
                  recogList.length >0 && 
                  recogList.map( (item,i) => (
                    <option value={item.uuid} key={i}>{getItemName(item.name)}</option>
                  ))
                } 
              </select>
            </div>
          </div>
        </Col>
        <Col lg={2}>          
          <img className="rounded-circle avatar-xl" src={loader_image} alt="img" width="80px" style={{margin:'0px',float:'right',display:'none',position:'absolute',right:'10px',top:'-17px',bottom:'9px'}} id="showSpinner" />
          <p className="recNotRec"><strong>{recog}</strong></p>
        </Col>
      </Row>
    </>
  );
};

export default GrandLodgeRecognition;
