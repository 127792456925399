import React, { Fragment , useEffect, useState} from 'react';
import {
  Card, CardHeader
} from 'reactstrap';// eslint-disable-next-line
import DashboardHeader from './DashboardHeader'; // eslint-disable-next-line
import DashboardMainContent from './DashboardMainContent'; // eslint-disable-next-line
import GrandLodgeRecognition from './GrandLodgeRecognition'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import {copiriPortalRequest,copiriPortalRequestWithoutToken,sessionLogout} from '../api/api';// eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import Loader from '../common/Loader';
import { toast } from 'react-toastify';
import './dashboard.css'; // eslint-disable-next-line
const Dashboard = () => {
  const [headerData, setHeaderData] = useState([]);
  const [showHideLoader,setShowHideLoader] = useState(true);
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [lodgeData, setLodeData] = useState([]);
  const [recognitionListData, setRecognitionListData] = useState([]);
  const [id,setId] = useState('');
  const [totalLodges,setTotalLodges] = useState(40);
  const [page,setPage] = useState(20);
  const [start,setStart] = useState(0);
  const [dropDownValue, setDropDownValue] = useState('');
  const [showHeaderError, setShowHeaderError] = useState(false);
  const [showSortColumn, setShowSortColumn] = useState(true);
  const [isEditable,setIsEditable] = useState(false);
  const [showPrivateView,setShowPrivateView] = useState(false);
  const MINUTE_MS = 500;
  useEffect(() => {
    if (localStorage.getItem('selected_org_id') && localStorage.getItem('selected_org_id') !== 'undefined') {
      getHeaderData(localStorage.getItem('selected_org_id'));
      //getLodgeData(localStorage.getItem('selected_org_id'));
      setDropDownValue(localStorage.getItem('selected_org_id'));
      getCountryData()
    } else {
      getHeaderData(1); 
      //getLodgeData(1); 
      setDropDownValue(1);
    }
    setShowPrivateView(false);
    getRecognitionListData();
    // eslint-disable-next-line
    const interval = setInterval(() => {
      changeOrgDropDown();
    }, MINUTE_MS);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  /* function to get country data and store in local storage*/  
  function getCountryData() {
    var sessionId = localStorage.getItem('session_id')
    if(sessionId && sessionId !=='undefined') {
      copiriPortalRequest(config.elol_api_url+'/list/countries',config.app_version,'get').then(response => {
        localStorage.setItem('countriesData',JSON.stringify(response.data));
      }).catch(error=>{
        if (error.response.status===401) {
          toast.error('Your session is expired. Please log in again');
          localStorage.setItem('session_id','');
          sessionLogout();
        }
      });
    } else {
      //history.push('/');
    }
  }

  function changeOrgDropDown() {
    var checkDropDown = localStorage.getItem('changeDropDown');
    if (checkDropDown && checkDropDown==='true') {
      setLodeData([]);
      setHeaderData([]);
      getHeaderData(localStorage.getItem('selected_org_id'),true,true);
      localStorage.setItem('changeDropDown',false);
      setShowSortColumn(true);
    }
  }

  /* function to get the header data */
  function getHeaderData(id,showLoader=true,showDataTable=true) {
    setShowHideLoader(showLoader);
    copiriPortalRequest(config.elol_api_url+'/grandlodges/'+id+'/header',config.app_version,'get').then(response =>
    {
      if (response.data.private_text && response.data.private_text !=='' && response.data.private_text !== null) {
        setShowPrivateView(true);
      } else {
        setShowPrivateView(false);
      }
      
      setShowHeaderError(false);
      if (showDataTable) {
        getLodgeData(id);
      }
      setShowHideLoader(false);
      if (response.data.uuid) {
        setHeaderData(response.data);
        
        if (response.data.address[0] && response.data.address[0] !== '') {
          setAddress1(response.data.address[0]);
        } else {
          setAddress1('');
        }
        
        if (response.data.address[1] && response.data.address[1] !== '') {
          setAddress2(response.data.address[1]);
        } else {
          setAddress2('');
        }
        
        if (response.data.address[2] && response.data.address[2] !== '') {
          setAddress3(response.data.address[2]);
        } else {
          setAddress3('');
        }
        
        if (response.data.address[3] && response.data.address[3] !== '') {
          setAddress4(response.data.address[3]);
        } else {
          setAddress4('');
        }
      }
      //setShowHideLoader(false);
    }).catch(error=>{
      setShowPrivateView(false);
      if (showDataTable) {
        getLodgeData(id);
      }
      if (error.response !==undefined) {
        if (error.response.status===401) {
          toast.error('Your session has expired. Please log in again.');
          sessionLogout();
        }
        
        if (error.response.status===404) {
          alert(error.response.data.error);
          setShowHeaderError(true);
        }
        if (error.response.status===500) {
          alert(error.response.data.error);
          setShowHeaderError(true);
        }
      }
      
      setShowHideLoader(false);
    });
  }

  /* function to get the data table listing */
  function getLodgeData(id,offset=1,sort=1,order='ASC') {
    var url = config.elol_api_url+'/grandlodges/'+id+'/lodges?sort='+sort+'&order='+order+'&offset='+(offset);
    copiriPortalRequest( url,config.app_version,'get').then(response => {
      if (response.status===401) {
        toast.error('Your session has expired. Please log in again.');
        sessionLogout();
      }
      if (response.data.lodges && response.data.lodges.length > 0) {
        setLodeData(response.data.lodges);
        setId(response.data.uuid);
        setTotalLodges(response.data.total);
        setPage(response.data.page);
        setStart(response.data.start);
        if (response.data.editable && response.data.editable===true ) {
          setIsEditable(true);
        } else {
          setIsEditable(false);
        }
      }
    }).catch(error=>{
      if (error.response !==undefined) {
        if (error.response.status===401) {
          toast.error('Your session has expired. Please log in again.');
          sessionLogout();
        }
        if (error.response.status===404) {
          alert(error.response.data.error);
        }
      }
      setShowHideLoader(false);
    });
  }

  function getRecognitionListData() {
    var grandLodges = JSON.parse(localStorage.getItem('recognitionData'));
    if (grandLodges.length>0) {
      setRecognitionListData(grandLodges);
    } else {
      copiriPortalRequest(config.elol_api_url+'/grandlodges?filter=full',config.app_version,'get').then(response => {
      if (response.data.grandlodges.length >0 ) {
        setRecognitionListData(response.data.grandlodges)
        localStorage.setItem('recognitionData',JSON.stringify(response.data.grandlodges));
      }
      }).catch(error=>{
        setShowHideLoader(false);
      });
    }
    
  }

  function updateGrandLodgeData() {
    setHeaderData([]);
    getHeaderData(id,true,false);
  }

  function updateLodgeData() {
    var sort = localStorage.getItem('lodgeSort');
    var order = localStorage.getItem('lodgeOrder');
    var offset = localStorage.getItem('lodgeOffset');

    if (sort==='' || sort===undefined || sort===null) {
      sort = 2;
    }

    if (order==='' || order===undefined || order===null) {
      order = 'ASC';
    }

    if (offset==='' || offset===undefined || offset===null) {
      offset = 1;
    }
    setShowSortColumn(false);
    setLodeData([]);
    getLodgeData(id,1,1,'ASC');
  }
  
  return (
    <Fragment>
    { showPrivateView &&
      <div className="alert alert-danger" role="alert" style={{height:'auto', padding:'2px 3px'}}>
        <i className="fas fa-exclamation-triangle"></i><span style={{marginLegt:'5px',fontSize:'12px'}}>  This Grand Lodge is PRIVATE and not visible to the public.</span>
      </div>
    }
      <Card className="mb-3">
        { showHideLoader ? <Loader /> : '' }
        <CardHeader className={`position-relative min-vh-25`}>
        { showHeaderError ?
          <div className="text-center mb-3">
            <h2>Data Load Failed</h2>
            <p>Please Reload This Page</p>
          </div>
        :
          <DashboardHeader headerData={headerData} address1={address1} address2={address2} address3 = {address3} address4 = {address4} isLogin={1} updateGrandLodgeData={updateGrandLodgeData}/>
        }
        </CardHeader>
      </Card>
      <Card className="mb-3">
        <div className="container plr-10 loggedInRecognition">
          <br />  
          <GrandLodgeRecognition headerValue={dropDownValue} recognitionListData={recognitionListData} isLogin={1}/>
          <br />
        </div>
      </Card>
      <Card className="mb-3">
        <DashboardMainContent lodgeData ={lodgeData}  id={id} totalLodges={totalLodges} page={page} start={start} isLogin={1} updateLodgeData={updateLodgeData} showSortColumn={showSortColumn} isEditable={isEditable}/>
      </Card>
    </Fragment>
  );
};

export default Dashboard;
