/* eslint react/no-multi-comp: 0, react/prop-types: 0 */// eslint-disable-next-line
import React , { useState,useEffect } from 'react';
// eslint-disable-next-line
import {CardHeader, Row,Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Form,Input,Label,FormGroup,Table,Alert,Media} from 'reactstrap';// eslint-disable-next-line
import {sessionLogout,copiriAxiosGet,copiriPortalRequest} from '../api/api'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import { toast } from 'react-toastify';// eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';// eslint-disable-next-line
import FormGroupInput from '../common/FormGroupInput';// eslint-disable-next-line
import axios from 'axios';// eslint-disable-next-line
import logo from '../../assets/img/logos/logo.png';
const SecurityPopUp = (props) => {// eslint-disable-next-line 

	/* function to request API to update security popup*/
	function updateSecurityPopup() {
		var token = localStorage.getItem('session_id');
		var data = {};
		data['legal'] = true;

		var uuid = localStorage.getItem('login_user_id');
		axios({
			method: 'patch', 
			url: config.elol_api_url+'/users/'+uuid, 
			data:data,
			headers: {
				'Authorization': 'Bearer '+token,
				'Content-Type' : 'application/json',
			}
		}).then(response => {
			props.onClick();
		}).catch(error=>{
			props.onClick();
		});
	}

  return (
    <>
      <Modal isOpen={props.showHide}  size="" id="">
        <ModalBody >
          	<Row align="center">
	          <Col lg={12}>
	            <img className="mr-2" src={logo} alt="The Seal of the Conference of Grand Secretaries in North America" width="50px" />
	          </Col>
	          <Col lg={12}>
	            <p><strong>Welcome to the COGSNA Electronic List of Lodges!</strong></p>
	          </Col>
	        </Row>

	        <Row>
	          <Col lg={12}>
	          	<div style={{fontSize:'13px'}}>
				<p><strong>Please Note:</strong> This system allows people from around the world to see your Grand Lodge and its data.</p>
		           	<p>If you choose to make your Grand Lodge's data public, you also agree to maintain your Grand Lodge's data and ensure that it is correct.  You may do this manually, or your designated Member Management System may perform updates in an automated fashion.</p>
		           	<p>Any data that you choose to make public <strong>will be available to anyone on the internet</strong>.  You may change any information to private at any time, which will hide it from visitors.  Should you have any questions, please refer to the "Help & FAQ" link in the menu.</p>
				<p>Most importantly, welcome!  We hope you find the <strong>Electronic List of Lodges</strong> to be a useful tool.</p>
		        </div>
	          </Col>
	        </Row>
        </ModalBody>
        <ModalFooter>
          <Col lg={12} style={{textAlign:'center'}}>
            <Button color="warning" style={{marginLeft:'20px'}} onClick={updateSecurityPopup}>I Understand and Accept</Button>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SecurityPopUp;
