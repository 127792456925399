import React , { useEffect, useState } from 'react'; // eslint-disable-next-line
import { Table, Row, Col, Button } from 'reactstrap';// eslint-disable-next-line
import axios from 'axios';// eslint-disable-next-line
import config from '../config/config';
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import {copiriPortalRequest, copiriPortalRequestWithoutToken,sessionLogout} from '../api/api'; // eslint-disable-next-line
import { copy } from '../common/Common';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import LodgeModal from './LodgeModal';
import CopiriLoader from '../common/CopiriLoader';// eslint-disable-next-line
const DashboardMainContent = (props) => {// eslint-disable-next-line
	const [lodgeData, setLodeData] = useState([]);
	const [lodgeModalData, setLodeModalData] = useState([]);
	const [showHideLoader,setShowHideLoader] = useState(false);
	const [loggedUser,setLoggedUser] = useState(false);
	const [isEditable,setIsEditable] = useState(false);
	const [selectedPage, setSelectedPage] = useState(''); // eslint-disable-next-line
	const [showHide,setShowHide] = useState(false);
	const [pagiArray, setPagiArray] = useState([]); // eslint-disable-next-line
	const [id,setId] = useState('');
	const [addEditLodge, setAddEditLodge] = useState('');
	// eslint-disable-next-line
	const [nameAscDesc,setNameAscDesc] = useState('asc');// eslint-disable-next-line
	const [numberAscDesc,setNumberAscDesc] = useState('asc');// eslint-disable-next-line
	const [locationAscDesc,setLocationAscDesc] = useState('asc');// eslint-disable-next-line
	const [statusAscDesc,setStatusAscDesc] = useState('asc');// eslint-disable-next-line
	const [privateAscDesc,setPrivateAscDesc] = useState('asc');// eslint-disable-next-line
	const [checkSortByName,setCheckSortByName] = useState(false);// eslint-disable-next-line
	const [checkSortByNumber,setCheckSortByNumber] = useState(false);// eslint-disable-next-line
	const [checkSortByLocation,setCheckSortByLocation] = useState(false);// eslint-disable-next-line
	const [checkSortByStatus,setCheckSortByStatus] = useState(false);// eslint-disable-next-line
	const [checkSortByPrivate,setCheckSortByPrivate] = useState(false);// eslint-disable-next-line
	const [checkSortColumn,setCheckSortColumn] = useState(1);// eslint-disable-next-line
  const [checkOrderBy, setCheckOrderBy] = useState('ASC');// eslint-disable-next-line
  const [checkOffset, setCheckOffset] = useState(0);
  const [uuid,setUUID] = useState('');
	useEffect(() => {
		if (props.lodgeData.length >0) {
			setLodeData([]);
			getLodgeData(props.lodgeData);
			setPagiArray(props.totalLodges / props.page);
			setId(props.id);
			setUUID(props.id);
			if (props.showSortColumn && props.showSortColumn===true) {
				deactiveAllSorting();
				setCheckSortByNumber(true);
			}
			if (props.isEditable && props.isEditable===true ) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
		}
		return () => setLodeData([]);
	// eslint-disable-next-line
	}, [props]);
	
	function getLodgeData(data) {
		if (props.isLogin) {
			setLoggedUser(true);
		} else {
			setLoggedUser(false);
		}
		if (data.length>0) {
			setLodeData(data);
		} else {
			var html = '<tr><td colSpan="5" align="center"><h4>No Lodges Found for This Grand Lodge</h4></td></tr>';
			document.getElementById('lodgeTableBody').innerHTML = html;
		}
	}
	/* function to check the status*/
	// eslint-disable-next-line
	function checkStatus(item) {
		if (item.status === 'Active') {
			return <span className="badge badge-success">{item.status}</span>;
		} else {
			return <span className="badge badge-secondary membersWarning">{item.status}</span>;
		}
	}

	/* function to check the Private*/
	// eslint-disable-next-line
	function checkPrivate(item) {
		if (item.private) {
			return <span className="badge badge-warning">Private</span>;
		} else {
			return <span className="badge badge-secondary defaultWarning">Public</span>;
		}
	}
	/* function to copy content*/
	// eslint-disable-next-line
	function copyContent(content) {
		copy(content);
		toast.success('Copied!');
	}

	/* function to handle the pagination*/
	function handlePagination(value) {
		setLodeData([]);
		setSelectedPage(value.selected)
		var token = localStorage.getItem('session_id');
		setCheckOffset(value.selected+1);
		if(token && token!== 'undefined') {
			updateLodgeDataWithToken(value.selected,checkSortColumn,checkOrderBy);
		} else {
			updateLodgeDataWithoutToken(value.selected,checkSortColumn,checkOrderBy);
		}
	}

	function updateLodgeDataWithToken(offset=1,sort=1,order='ASC') {

		localStorage.setItem('lodgeOffset',(offset+1));
		localStorage.setItem('lodgeSort',sort);
		localStorage.setItem('lodgeOrder',order);
		//setShowHideLoader(true);
		var url = config.elol_api_url+'/grandlodges/'+uuid+'/lodges?sort='+sort+'&order='+order+'&offset='+(offset+1);
		setCheckSortColumn(sort);
    setCheckOrderBy(order);
    setCheckOffset(offset);
		copiriPortalRequest( url,config.app_version,'get').then(response => {
      if (response.data.lodges.length > 0) {
      	getLodgeData(response.data.lodges)
      } else {
      	var html = '<tr><td colSpan="5" align="center"><h4>No Lodges Found for This Grand Lodge</h4></td></tr>';
				document.getElementById('lodgeTableBody').innerHTML = html;
      }
      setShowHideLoader(false);
    }).catch(error=>{
      setShowHideLoader(false);
      if (error.response.status===401) {
        toast.error('Your session has expired. Please log in again.');
        sessionLogout();
      }
        
      if (error.response.status===404 || error.response.status===400 || error.response.status===500) {
        var html = '<tr><td colSpan="5" align="center"><h4>Data Load Failed. Please Reload This Page</h4></td></tr>';
				document.getElementById('lodgeTableBody').innerHTML = html;
      }
    });
	}

	function updateLodgeDataWithoutToken(offset=1,sort=1,order='ASC') {
		//setShowHideLoader(true);
		var url = config.elol_api_url+'/grandlodges/'+uuid+'/lodges?sort='+sort+'&order='+order+'&offset='+(offset+1);
		setCheckSortColumn(sort);
    setCheckOrderBy(order);
    setCheckOffset(offset);
		copiriPortalRequestWithoutToken( url,config.app_version,'get').then(response => {
      if (response.data.lodges && response.data.lodges.length > 0) {
      	getLodgeData(response.data.lodges)
      } else {
      	var html = '<tr><td colSpan="5" align="center"><h4>No Lodges Found for This Grand Lodge</h4></td></tr>';
				document.getElementById('lodgeTableBody').innerHTML = html;
      }
      setShowHideLoader(false);
    }).catch(error=>{
      setShowHideLoader(false);
      if (error.response.status===401) {
        toast.error('Your session has expired. Please log in again.');
        sessionLogout();
      }
        
      if (error.response.status===404 || error.response.status===400 || error.response.status===500) {
        var html = '<tr><td colSpan="5" align="center"><h4>Data Load Failed. Please Reload This Page</h4></td></tr>';
				document.getElementById('lodgeTableBody').innerHTML = html;
      }
    });
	}

	/* function to disable the pagination tab*/
	function disableTab() {
		if (selectedPage <= 1) {
			return 'disable-prev-tab';
		}
		if (selectedPage === Math.round(pagiArray)) {
			return 'disable-next-tab';
		}
		return '';
	}

	/* function to close modal */
	const closeModal = () => {
    	setShowHide(!showHide);
  	}
  	/* function to open modal */
	const openLodgeModal = (item) => {
		setId(item.uuid);
		setShowHide(!showHide);
		setLodeModalData(item);
		setAddEditLodge('update');
	}

	function openAddNewLodgeModal() {
		setId('add');
		setShowHide(!showHide);
		setLodeModalData([]);
		setAddEditLodge('add');
	}
	/* function to update private */
	// eslint-disable-next-line
	function updatePrivate(uid,type) {
		//var token = localStorage.getItem('session_id');
		var data = {};
		data['uuid'] = uid;
		if (type==='private') {
			data['private'] = false;
		} else {
			data['private'] = true;
		}
	}

	/* function to format number*/
  function formatNumber(num) {
  	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  // eslint-disable-next-line
  function checkNameAscDesc() {
    if (checkSortByName) {
      return ascDescIcon(nameAscDesc);
    }
  }

  
  // eslint-disable-next-line
  function checkLocationAscDesc() {
    if (checkSortByLocation) {
      return ascDescIcon(locationAscDesc);
    }
  }

  // eslint-disable-next-line
  function checkNumberAscDesc() {
    if (checkSortByNumber) {
      return numericAscDescIcon(numberAscDesc);
    }
  }

  // eslint-disable-next-line
  function checkStatusAscDesc() {
    if (checkSortByStatus) {
      return ascDescIcon(statusAscDesc);
    }
  }

  // eslint-disable-next-line
  function checkPrivateAscDesc() {
    if (checkSortByPrivate) {
      return ascDescIcon(privateAscDesc);
    }
  }

  function ascDescIcon(type) {
    if (type === 'asc') {
      return <i className="fas fa-sort-alpha-up"></i>;
    } else if (type === 'desc') {
      return <i className="fas fa-sort-alpha-down"></i>;
    } else {
      return '';
    }
  }

  function numericAscDescIcon(type) {
    if (type === 'asc') {
      return <i className="fas fa-sort-numeric-up"></i>;
    } else if (type === 'desc') {
      return <i className="fas fa-sort-numeric-down"></i>;
    } else {
      return '';
    }
  }

  /* function to deactive all sorting(Asc/Desc) icon*/
  function deactiveAllSorting() {
    setCheckSortByName(false);
    setCheckSortByNumber(false);
    setCheckSortByLocation(false);
    setCheckSortByStatus(false);
    setCheckSortByPrivate(false);
  }

  /* function to perform sorting*/
  function sortingData(column) {
    setLodeData([]);
    //deactiveAllSorting();
    let sortColumn = 2;
    let orderBy = 'DESC';
    
    // eslint-disable-next-line
    if (column == 1) {      
      sortColumn = 1;
      if (checkSortByNumber) {
      	deactiveAllSorting();
      	setCheckSortByNumber(true);
      	if (numberAscDesc === 'asc') {
	        setNumberAscDesc('desc');
	        orderBy = 'DESC';
	      } else {
	        setNumberAscDesc('asc');
	        orderBy = 'ASC';
	      }
      } else {
      	deactiveAllSorting();
      	setCheckSortByNumber(true);
      	setNumberAscDesc('asc');
      	orderBy = 'ASC';
      }
      
    }
    
    // eslint-disable-next-line
    if (column == 2) {      
      sortColumn = 2;
      if (checkSortByName) {
      	deactiveAllSorting();
      	setCheckSortByName(true);
      	if (nameAscDesc === 'asc') {
        setNameAscDesc('desc');
        orderBy = 'DESC';
	      } else {
	        setNameAscDesc('asc');
	        orderBy = 'ASC';
	      }
      } else {
      	deactiveAllSorting();
      	setCheckSortByName(true);
      	setNameAscDesc('asc');
      	orderBy = 'ASC';
      }
      
    }

    // eslint-disable-next-line
    if (column == 3) {
      sortColumn = 3;
      if (checkSortByLocation) {
      	deactiveAllSorting();
      	setCheckSortByLocation(true);
      	if (locationAscDesc === 'asc') {
	        setLocationAscDesc('desc');
	        orderBy = 'DESC';
	      } else {
	        setLocationAscDesc('asc');
	        orderBy = 'ASC';
	      }
      } else {
      	deactiveAllSorting();
      	setCheckSortByLocation(true);
      	setLocationAscDesc('asc');
        orderBy = 'ASC';
      }      
    }

    // eslint-disable-next-line
    if (column == 4) {      
      sortColumn = 4;
      if (checkSortByStatus) {
      	deactiveAllSorting();
      	setCheckSortByStatus(true);
      	if (statusAscDesc === 'asc') {
	        setStatusAscDesc('desc');
	        orderBy = 'DESC';
	      } else {
	        setStatusAscDesc('asc');
	        orderBy = 'ASC';
	      }
      } else {
      	deactiveAllSorting();
      	setCheckSortByStatus(true);
      	setStatusAscDesc('asc');
        orderBy = 'ASC';
      }
    }

    // eslint-disable-next-line
    if (column == 5) {
      setCheckSortByPrivate(true);
      sortColumn = 5;
      if (checkSortByPrivate) {
      	deactiveAllSorting();
      	setCheckSortByPrivate(true);
      	if (privateAscDesc === 'asc') {
	        setPrivateAscDesc('desc');
	        orderBy = 'DESC';
	      } else {
	        setPrivateAscDesc('asc');
	        orderBy = 'ASC';
	      }
      } else {
      	deactiveAllSorting();
      	setCheckSortByPrivate(true);
      	setPrivateAscDesc('asc');
        orderBy = 'ASC';
      }
      
    }

    if (loggedUser) {
    	updateLodgeDataWithToken(checkOffset,sortColumn,orderBy)
    } else {
    	updateLodgeDataWithoutToken(checkOffset,sortColumn,orderBy)
    }
    setCheckSortColumn(sortColumn);
    setCheckOrderBy(orderBy);

  }


	/* Return the pagination HTML */
  const renderPagination = (className = '') => {
  	if (props.totalLodges !=='' && props.page !=='') {
	    return (
	      <div className={`d-inline-block ${className} `} style={{marginRight:'2px'}}>
	        <ReactPaginate
	          previousLabel={'Previous'}
	          nextLabel={'Next'}
	          breakLabel={'...'}
	          breakClassName={'break-me'}
	          pageCount={Math.round(pagiArray) === 0 ? 1 : Math.round(pagiArray)}
	          marginPagesDisplayed={2}
	          pageRangeDisplayed={3}
	          forcePage={selectedPage}
	          onPageChange={handlePagination}
	          containerClassName={`pagination react-paginate ${disableTab()}`}
	          activeClassName={'active'}
	        />
	      </div>
	    )
	  }
  }
return (
	<>
	<div className="container">
		
			<div className="lodge-main">
				<h5 className="mb-0">{formatNumber(props.totalLodges)} Subordinate Lodges</h5>
				<div className="lodgePagination">
					{ loggedUser && isEditable &&
						<Button className="btn btn-falcon-default btn-sm" onClick={() => openAddNewLodgeModal()}>
	          	Add a Lodge
	      		</Button>
		      }
		      { props.lodgeData && props.lodgeData.length >0 &&
						<>{renderPagination('')}</>

					}
				</div>
			</div>
		<Table className="table table-dashboard table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap " >
			<thead style={{color:'#344050'}}>
				<tr>
				{ loggedUser ? <>
					<th style={{textAlign:'left', cursor:'default'}} >
          	<span onClick={() => sortingData('2')} className="lodgeSorting">Lodge Name {checkNameAscDesc()}</span>
						<span onClick={() => sortingData('1')} className="lodgeSorting"> / Number {checkNumberAscDesc()}</span>
					</th>
					<th style={{textAlign:'left', cursor:'default'}} >
						<span onClick={() => sortingData('3')} className="lodgeSorting">Location {checkLocationAscDesc()}</span>
					</th>
					{ isEditable &&
						<th style={{textAlign:'center', cursor:'default'}} >
							<span onClick={() => sortingData('4')} className="lodgeSorting">Status {checkStatusAscDesc()}</span>
						</th>
					}
					<th style={{textAlign:'center', cursor:'default'}} >
						<span onClick={() => sortingData('5')} className="lodgeSorting">Private? {checkPrivateAscDesc()}</span>
					</th>
					{ isEditable &&
						<th style={{textAlign:'center', cursor:'default'}}><span className="">Change</span></th>
					}
				</>:<>					
					<th style={{textAlign:'left',width:'70%',fontWeight:'bold'}} >
						<span onClick={() => sortingData('2')} className="lodgeSorting">Lodge Name {checkNameAscDesc()}</span>
						<span onClick={() => sortingData('1')} className="lodgeSorting"> / Number {checkNumberAscDesc()}</span>
					</th>
					<th style={{textAlign:'left',width:'30%',fontWeight:'bold'}} >
						<span onClick={() => sortingData('3')} className="lodgeSorting">Location {checkLocationAscDesc()}</span>
					</th>
				</>
					}
				</tr>
			</thead>
			<tbody id="lodgeTableBody">
				{
    			lodgeData.length >0 && 
	    			lodgeData.map( (item,i) => (
							<tr key={i}>
								<td><span id={"org_name_"+item.uuid} >{item.formatted}</span></td>
								<td>{item.location}</td>
								{
									loggedUser ? 
										<>
										{ isEditable &&
											<td style={{textAlign:'center', cursor:'default', textTransform:'uppercase'}}><span className="">{checkStatus(item)}</span></td>
										}
										<td style={{textAlign:'center', cursor:'default', textTransform:'uppercase'}}><span className="">{checkPrivate(item)}</span></td>
										{ isEditable &&
											<td style={{textAlign:'center', cursor:'pointer'}}>
												<Badge color="primary" className="LodgeMoreBadge" onClick={() => openLodgeModal(item)}>CHANGE</Badge>
											</td>
										}
										</>
									: <></>
								}
							</tr>
						))
        	}				
			</tbody>
		</Table>
		{ props.lodgeData && props.lodgeData.length >0 &&
			<div className="lodge-main">
				<div className="lodgePagination">
					{/*<Button className="btn btn-falcon-default btn-sm" onClick={() => openAddNewLodgeModal()}>
		          Add Lodge
		      </Button>*/}
					{renderPagination('')}
				</div>
			</div>
		}
	</div>
	<LodgeModal showHide={showHide} onClick={closeModal} updateModal={closeModal} id={id} lodgeModalData={lodgeModalData} updateLodgeData={props.updateLodgeData} addEditLodge={addEditLodge} />
	{ showHideLoader ? <CopiriLoader />: ''}
	</>
);
}

export default DashboardMainContent;
