import config from './components/config/config';

export const demographicsRoutes = {
  name: 'Demographics',
  to: '/demographics',
  icon: 'fas fa-chess-rook-alt',
};

export const homeRoutes = {
  name: 'Home',
  to: '/home',
  exact: true,
  icon: 'fas fa-home',
};

export const documentationRoutes = {
  name: 'Help & FAQ',
  to: '/faq',
  exact: true,
  icon: 'fas fa-book'
};

export const changelogRoutes = {
  name: 'Updates',
  to: '/changelog',
  exact: true,
  icon: 'fas fa-code-branch',
  badge: {
    text: `v${config.site_version}`,
    color: 'soft-primary'
  }
};
 


export const recognitionRoutes = {
  name: 'Recognition',
  to: '/recognition',
  exact: true,
  icon: 'fas fa-globe'
};

export const membersRoutes = {
  name: 'Users',
  to: '/users',
  exact: true,
  icon: 'fas fa-users'
};

export const statisticsRoutes = {
  name: 'Statistics',
  to: '/statistics',
  exact: true,
  icon: 'fas fa-analytics'
};

export const resourcesRoutes = {
  name: 'Resources',
  to: '/resources',
  exact: true,
  icon: 'fas fa-folder-open'
};

export const profileRoutes = {
  name: 'Profile',
  to: '/profile',
  exact: true,
  icon: 'fas fa-user-circle'
};

export const apiDocRoutes = {
  name: 'API Docs',
  to: '/api/doc',
  exact: true,
  icon: 'fas fa-brackets-curly'
};

export const logoutRoutes = {
  name: 'Log Out',
  to: '/logout',
  exact: true,
  icon: 'fas fa-sign-out-alt'
};

export const SystemOverview = {
  name: 'System Overview',
  to: '/pages/admin/cso',
  exact: true,
  icon: 'fas fa-analytics'
};

export const MSASTB = {
  name: 'MSA STBs',
  to: '/pages/admin/mstb',
  exact: true,
  icon: 'fas fa-podcast'
};

export const rightSideMenuList = [
  {
    name: 'System Overview',
    to: '/pages/admin/cso',
    icon: 'fas fa-analytics',
    divider: false,
  },  
  {
    name: 'MSA STBs',
    to: '/pages/admin/mstb',
    icon: 'fas fa-podcast',
    divider: true,
  },
];

export default [
  homeRoutes,
  recognitionRoutes,
  profileRoutes,
  documentationRoutes,
  apiDocRoutes,
  logoutRoutes,
];
