import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef,useState } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import bgNavbarImg from '../../assets/img/generic/bg-navbar.png';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import routes from '../../routes';
import Flex from '../common/Flex';
import Logo from './Logo';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton'; // eslint-disable-next-line
import  { Redirect } from 'react-router-dom';// eslint-disable-next-line
import OrgDropDown from './OrgDropDown';
import SecurityPopUp from './SecurityPopUp';
import UpdatePasswordPopup from './UpdatePasswordPopup';
import { useHistory } from 'react-router-dom';
const NavbarVertical = ({ navbarStyle }) => {
  const navBarRef = useRef(null);
  const history = useHistory();
  const [showHideSecurityPopup,setShowHideSecurityPopup] = useState(false);
  const [showPasswordUpdatePopup,setShowPasswordUpdatePopup] = useState(false);
  const [checkPasswordUpdatePopup,setCheckPasswordUpdatePopup] = useState(false);
  var checkSession = localStorage.getItem('session_id');
  localStorage.setItem('last_url_location', history.location.pathname);// eslint-disable-next-line
  const {
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    isCombo,
    setShowBurgerMenu,
    setNavbarCollapsed
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (!isNavbarVerticalCollapsed) {
      checkSecurityPopup();  
    }
    
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  /* function to check security popu when user first time visit the site*/
  function checkSecurityPopup() {
    var showSecurutyPopup =localStorage.getItem('showSecurityPopup'); 
    var showPasswordPopup =localStorage.getItem('showSPasswordPopup'); 
    if (showSecurutyPopup==='yes') {
      setShowHideSecurityPopup(true);
      if (showPasswordPopup==='yes') {
        setCheckPasswordUpdatePopup(true);
      } else {
        setCheckPasswordUpdatePopup(false);
      }
    } else {
      setShowHideSecurityPopup(false);
      if (showPasswordPopup==='yes') {
        setShowPasswordUpdatePopup(true);
      } else {
        setShowPasswordUpdatePopup(false);
      }
    }

  }

  /* function to close modal */
  const closeModal = () => {
    setShowHideSecurityPopup(false);
    localStorage.setItem('showSecurityPopup','no');
    if (checkPasswordUpdatePopup) {
      setShowPasswordUpdatePopup(true);
    }
  }

  /* function to close pasword update modal */
  const closePasswordModal = () => {
    setShowPasswordUpdatePopup(false);
    localStorage.setItem('showSPasswordPopup','no');
  }

  if (!checkSession) {
    return <Redirect to='/'/>;
  }
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={40} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
        style={
          navbarStyle === 'vibrant' && {
            backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`
          }
        }
      >
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} />
          <OrgDropDown />
          <SecurityPopUp showHide={showHideSecurityPopup} onClick={closeModal} />
          <UpdatePasswordPopup showHide={showPasswordUpdatePopup} onClosePaswordModal={closePasswordModal} />
        </Nav>
        <div className="settings px-3 px-xl-0">
          {isCombo && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu} />
              </Nav>
            </div>
          )}
          <div className="navbar-vertical-divider">
            <p />
          </div>
        </div>
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'transparent'
};

export default NavbarVertical;
