import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';
import AppContext from '../../context/Context';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import ProfileModal from './ProfileModal';

const NavbarVerticalMenu = ({ routes, location }) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  const [showHideProfileModal, setShowHideProfileModal] = useState(false);
  const { setShowBurgerMenu } = useContext(AppContext);
  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

  
  const showProfileModal = (e) => {
    e.preventDefault();
    setShowHideProfileModal(true);
  }

  /* function to close modal */
  const closeModal = () => {
    setShowHideProfileModal(false)
  }

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const getHr = name => {
    if (name === 'Log Out' || name === 'Users' || name === 'Profile') {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };
  
  const getNavLink = (name,route) => {
    if (name !== 'Profile') {
      return (
        <NavItem>
          <NavLink className = "nav-link" {...route} onClick={() => setShowBurgerMenu(false)} >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </NavItem>
      )
    } else {
      return (
        <NavItem>
          <NavLink className = "nav-link" {...route} onClick={e => showProfileModal(e)} >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
          <ProfileModal showHide={showHideProfileModal} onClick={closeModal} />
        </NavItem>
      )
    }
  }

  return routes.map((route, index) => {
    if (!route.children) {
      return (
        <Fragment key={index}>
          {getHr(route.name)}
          {getNavLink(route.name,route)}          
        </Fragment>
      );
    }
    return (
      <Fragment key={index}>
        {getHr(route.name)}
        <NavItem>
          <BootstrapNavLink
            onClick={e => toggleOpened(e, index)}
            className="dropdown-indicator cursor-pointer"
            aria-expanded={openedIndex === index}
          >
            <NavbarVerticalMenuItem route={route} />
          </BootstrapNavLink>
          <Collapse isOpen={openedIndex === index}>
            <Nav>
              <NavbarVerticalMenu routes={route.children} location={location} />
            </Nav>
          </Collapse>
        </NavItem>

      </Fragment>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);
