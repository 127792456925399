/* eslint react/no-multi-comp: 0, react/prop-types: 0 */// eslint-disable-next-line
import React , { useState,useEffect } from 'react';
// eslint-disable-next-line
import {CardHeader, Row,Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Form,Input,Label,FormGroup,Table,Alert,Media} from 'reactstrap';// eslint-disable-next-line
import {sessionLogout,copiriAxiosGet,copiriPortalRequest} from '../api/api'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import { toast } from 'react-toastify';// eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';// eslint-disable-next-line
import FormGroupInput from '../common/FormGroupInput';
import axios from 'axios';// eslint-disable-next-line
const LodgeModal = (props) => {// eslint-disable-next-line
  const [showHideLoader1,setShowHideLoader1] = useState(false);
  const [lodgeName,setLodgeName] = useState('');// eslint-disable-next-line
  const [lodgeNumber,setLodgeNumber] = useState('');// eslint-disable-next-line
  const [formattedLodgeName, setFormattedLodgeName] = useState('');// eslint-disable-next-line
  const [lodgeUD,setLodgeUD] = useState('');// eslint-disable-next-line
  const [disableInputIsChecked, setDisableInputIsChecked] = useState(false);// eslint-disable-next-line
  const [nameError, setNameError] = useState('');// eslint-disable-next-line
  const [numberError, setNumberError] = useState('');// eslint-disable-next-line
  const [checkInputChange,setCheckInputChange] = useState(false);// eslint-disable-next-line
  const [numberDisabled,setNumberDisabled] = useState(false);// eslint-disable-next-line
  const [id,setId] = useState('');// eslint-disable-next-line
  const [city, setCity] = useState('');// eslint-disable-next-line
  const [country, setCountry] = useState('');// eslint-disable-next-line
  const [state, setState] = useState('');// eslint-disable-next-line
  const [state2, setState2] = useState('');// eslint-disable-next-line
  const [cityError, setCityError] = useState('');// eslint-disable-next-line
  const [stateError, setStateError] = useState('');// eslint-disable-next-line
  const [countryError, setCountryError] = useState('');// eslint-disable-next-line
  const [stateData,setStateData] = useState([]); // eslint-disable-next-line
  const [checkStateData,setCheckStateData] = useState(false);// eslint-disable-next-line
  const [countriesData,setCountriesData] = useState([]); // eslint-disable-next-line
  const [status,setStatus] = useState('Active');
  const [checkPrivate,setCheckPrivate] = useState(true);
  const [showHidePrivate,setShowHidePrivate] = useState(false);
  const [addEditLodge,setAddEditLodge] = useState('');
  const [statusError, setStatusError] = useState('');// eslint-disable-next-line
  /* initial api request to get the lodge data*/
    useEffect(() => {                           // side effect hook
      if (props.id && props.showHide) {
        setNameError('');
        setNumberError('');
        setCityError('');
        setCountryError('');
        setStateError('');
        if (props.addEditLodge==='add') {
          setAddEditLodge('add');
        } else {
          setAddEditLodge('update');
        }
        var checkCData = (localStorage.getItem('countriesData'));
        setCountriesData(JSON.parse(checkCData));
        if (props.addEditLodge==='update') {
          setLodgeName(props.lodgeModalData.name);
          setLodgeNumber(props.lodgeModalData.number);
          setFormattedLodgeName(props.lodgeModalData.formatted);
          setCheckInputChange(false);
          setId(props.lodgeModalData.uuid)
          setCity(props.lodgeModalData.city);
          setCountry(props.lodgeModalData.country_id);
          getStatesData(props.lodgeModalData.country_id,props.lodgeModalData.state_id,props.lodgeModalData.state)
          if (props.lodgeModalData.private) {
            setCheckPrivate(true);
          } else {
            setCheckPrivate(false);
          }
          setStatus(props.lodgeModalData.status);
          setShowHidePrivate(true);
                    
          if (props.lodgeModalData.dispensation===true) {
            setDisableInputIsChecked(true);
            setNumberDisabled(true);
            setFormattedLodgeName(props.lodgeModalData.name+" Lodge, U.D");
          } else {
            setDisableInputIsChecked(false);
            setNumberDisabled(false);
          }
        } else {
          setLodgeName('');
          setFormattedLodgeName('');
          setCheckInputChange(false);
          setStateData([]);
          setCity('');
          setStatus('');
          setCheckPrivate(false);
          setState('');
          setCountry('');
          setCheckStateData(false);
          setLodgeNumber('');
          setNumberDisabled('');
          setDisableInputIsChecked(false);
          setShowHidePrivate(true);
        }
      }
      
      // eslint-disable-next-line
    }, [props])

  /* function to check the value of lodge name input box */
  const editLodgeName = event => {
    setLodgeName(event.target.value);
    setCheckInputChange(true);
    if (lodgeNumber !=='') {
      setFormattedLodgeName(event.target.value+" Lodge No " + lodgeNumber);
    } else {
      setFormattedLodgeName(event.target.value+" Lodge " + lodgeNumber);
    }
  };

   /* function to check the value of lodge number input box */
  const editLodgeNumber = event => {
    setLodgeNumber(event.target.value);
    setCheckInputChange(true);
    setFormattedLodgeName(lodgeName+" Lodge No. " + event.target.value);
  };

  function handleCheckboxClick(event) {
    setDisableInputIsChecked(prevValue => !prevValue); // invert value
    if (!disableInputIsChecked) {
      setNumberDisabled(true);
      setFormattedLodgeName(lodgeName+" Lodge, U.D");
    } else {
      setFormattedLodgeName(lodgeName+" Lodge No. "+ lodgeNumber);
      setNumberDisabled(false);
    }
    setCheckInputChange(true);
  }

   /* function to change country and state data */
  const changeCountry = event => {
    setCheckInputChange(true);
    if (event.target.value !=='') {
      setCountry(event.target.value);
      getStatesData(event.target.value,'');
      setState2('');
    } else {
      setCountry(event.target.value);
      setStateData([]);
      setState2('');
    }
  };

  /* function to get the states Data based on country id */
  function getStatesData(country_id,state_id,stateName) {
    copiriPortalRequest(config.elol_api_url+'/lists/countries/' + country_id + '/states',config.app_version,'get').then(response => {
      if (response.data.length>0) {
        setStateData(response.data);
        setCheckStateData(true);
        setState(state_id);  
      } else {
        setStateData([]);
        setCheckStateData(false);
        setState2(stateName);
      }
    }).catch(error=>{
      /* ... */
    });
  }

  /* function to get the value of state input box*/
  const changeState = event => {
    setState(event.target.value);
    setCheckInputChange(true);
  };

  /* function to get the value of state2 input box if coutnry's state not found, then user can manual enter */
  const changeState2 = event => {
    setState2(event.target.value);
    setCheckInputChange(true);
  };

  /* function to request API to change organization detail*/
  function submitOrganizationName() {
    setNameError('');
    setNumberError('');
    setCityError('');
    setCountryError('');
    setStateError('');
    setStatusError('');
    var method = '';
    var url = '';

    if (addEditLodge ==='add') {
      method = 'post';
      url = config.elol_api_url+'/lodges';
    } else {
      method = 'put';
      url = config.elol_api_url+'/lodges/'+id;
    }
    //return false;
    /* If neither a name nor number are present, say so. */
    if (lodgeName === '' && lodgeNumber === '') {
      setNameError('A Name or Number is Required.');
      setNumberError('A Name or Number is Required.');
    }
    /* If a city is not present, say so. */
    else if (city === '') {
      setCityError('The Lodge\'s City is Required.');
    }
    /* If a country is not present, say so. */
    else if (country === '') {
      setCountryError('The Lodge\'s Country is Required.');
    }
    /* If the states list is populated but a state is not selected, say so. */
    else if (stateData.length > 0 && state === '') {
      setStateError('Please Select a State.');
    }
    /* If a status is not present, say so. */
    else if (status==='') {
      setStatusError('Please Select a Status.');
    }

    /* Otherwise, move forward. */
    else {
      if (checkInputChange) {
        setShowHideLoader1(true);
        var token = localStorage.getItem('session_id');
        var dispensation = false;

        if  (lodgeNumber ==='') {
          dispensation = true;
        }

        if (disableInputIsChecked) {
          dispensation = true;
        } 
        
        var state_name = '';
        var state_id = '';
        if (!checkStateData) {
          state_name = state2;
        } 
        if (checkStateData) {
          state_id = state
        }
        var address = {"city":city,"country":country.toString(),"state":state_name,"state_id":state_id.toString()}        
        var data = {};        
        if (addEditLodge ==='update') {
          data['uuid']                = props.id;
        }
        
        data['grand_lodge_uuid']    = localStorage.getItem('selected_org_id');
        data['name']                = lodgeName;
        data['number']              = lodgeNumber;
        data['address']             = address;
        data['under_dispensation']  = dispensation;
        data['private']             = checkPrivate;
        data['status']              = status;
        
        axios({
          method: method, 
          url: url, 
          data:data,
          headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type' : 'application/x-www-form-urlencoded',
          }
        }).then(response => {
          setShowHideLoader1(false);
          
          if (response.status === 200) {
            toast.success('Success!')
          }

          if (response.status===401) {
            toast.error('Your session has expired. Please log in again.');
            sessionLogout();
          }
          document.getElementById('closeOrganizationModal').click()
          props.updateLodgeData();
          document.getElementById("org_name_"+id).innerHTML = formattedLodgeName;
        }).catch(error=>{
          setShowHideLoader1(false);
          if (error.response !==undefined) {
            if (error.response.status===401) {
              toast.error('Your session has expired. Please log in again.');
              sessionLogout();
            } else {
              var errors = error.response.data.errors;
              var msg = error.response.data.error;
              msg += "\r\n \r\n";
              if (errors.length>0) {
                for (var i=0; i<errors.length;i++) {
                  msg += "- " + errors[i] + "\r\n \r\n";
                }
              }
              alert(msg);
            }
          }
        });
      }
    }
  }

  /* function to get the value of city input box*/
  const changeCity = event => {
    setCity(event.target.value);
    setCheckInputChange(true);
  };

  /* function to change the private flag */
  const handleSwitchChange = event =>  {
    setCheckPrivate(!checkPrivate);
    setCheckInputChange(true);
  }

  /* function to change the status value */
  const handleStatusChange = event =>  {
    setStatus(event.target.value);
    setCheckInputChange(true);
  }

  function checkStatusActive(type) {
    if (type===status) {
      return true;
    }
  }
  return (
    <>
      <Modal isOpen={props.showHide} toggle={props.onClick} size="lg" id="lodgeHeaderModal">
        <ModalBody >
          <Row>
            <Col lg={9}>
              <FormGroupInput
                id       = "lodge-name"
                label    = "Lodge Name"
                type     = "text"
                value    = {lodgeName}
                onChange = {editLodgeName}
              />
              <span className="formValidationError">{nameError}</span>
            </Col>
            <Col lg={2}>
              <FormGroupInput
                id       = "number"
                label    = "Lodge No."
                type     = "text"
                value    = {lodgeNumber}
                onChange = {editLodgeNumber}
                disabled = {numberDisabled}
              />
              <span className="formValidationError">{numberError}</span>
            </Col>
            <Col lg={1}>
              <div className="form-group">
                <label style={{whiteSpace: 'nowrap', cursor: 'pointer'}} className="collapsed" aria-expanded="false">
                  U.D.
                  <i className="fas fa-question-circle"></i>
                </label>
                <input type="checkbox" className="form-control udCheckBox" name="provisional" id="upd_provisional" style={{marginTop: '0px'}} value={ disableInputIsChecked } checked={disableInputIsChecked} onChange={ handleCheckboxClick } />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroupInput
                id="formatted_lodge_name"
                label="Formatted Lodge Name"
                disabled
                value={formattedLodgeName}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="alert alert-warning" role="alert">
                <i className="fas fa-exclamation-triangle"></i> <b>IMPORTANT</b><br />
                Please <b><i>do not</i></b> include the word "Lodge" in your Lodge's name.  The system will do this automatically.<br />
                <br />
                The <b><i>only</i></b> time when the word "Lodge" may be used is if it is <i>actually</i> a part of the Lodge's name.  
                Examples include "<i>The Lodge of St. John</i>", "<i>Pythagoras Lodge of Research</i>", etc.
              </div>
            </Col>
          </Row>
          <p></p>
          <Row>
            <Col lg={12}>
              <FormGroupInput
                id          = "city"
                label       = "City"
                value       = {city}
                onChange    = {changeCity}
                placeholder = "City"
                type        = "text"
              />
              <span className="formValidationError">{cityError}</span>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <Label for="exampleName">Country</Label>
                    <select 
                    className = "form-control" 
                    value     = {country} 
                    onChange  = {changeCountry}
                    id        = "selectCountry"
                    >
                    <option value="">Select Country</option>
                      {
                        countriesData && countriesData.length >0 && 
                        countriesData.map( (item,i) => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                      ))
                      }
                    </select>
              </FormGroup>
              <span className="formValidationError">{countryError}</span>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="exampleName">State / Province</Label>
                { checkStateData ?
                  <>
                <select 
                className  = "form-control"  
                onChange   = {changeState}
                value      = {state}
                id         = "selectState"
                >
                <option value="">Select State</option>
                {
                  stateData.length > 0 && 
                  stateData.map( (item,i) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                  ))
                }
                </select>
                </>
                : <Input type="text" 
                  name="state" 
                  id="state" 
                  placeholder="State"
                  onChange={changeState2}
                  value={state2}
                  className="form-control"  />
                }
              </FormGroup>
               <span className="formValidationError">{stateError}</span>
            </Col>

            <Col lg={6} className="switch__parent">
              <FormGroup className="stus_grp">
                <Label for="exampleName">Status:</Label><br />
                <div className="modal__chekbox">
                  <div>
                    <label className="inputRadio"><Input type="radio" name="lodgeStatus" className="inputRadioBox" onChange={handleStatusChange} value="Active"       checked={checkStatusActive('Active')}       /> Active</label>
                    { showHidePrivate &&
                      <>
                      <div style={{float: 'right', marginRight: '20%'}}>
                        <label className="switch">
                          <input type="checkbox"  onChange={handleSwitchChange} checked={checkPrivate}/>
                          <span className="slider round"></span>
                        </label>
                        <Label for="exampleName" className="mr-2">Private?</Label>
                      </div>
                      </>
                    }
                    <br />
                  </div>
                  <label className="inputRadio"><Input type="radio" name="lodgeStatus" className="inputRadioBox" onChange={handleStatusChange} value="Suspended"    checked={checkStatusActive('Suspended')}    /> Suspended</label><br />
                  <label className="inputRadio"><Input type="radio" name="lodgeStatus" className="inputRadioBox" onChange={handleStatusChange} value="Merged"       checked={checkStatusActive('Merged')}       /> Merged</label><br />
                  <label className="inputRadio"><Input type="radio" name="lodgeStatus" className="inputRadioBox" onChange={handleStatusChange} value="Consolidated" checked={checkStatusActive('Consolidated')} /> Consolidated</label><br />
                  <label className="inputRadio"><Input type="radio" name="lodgeStatus" className="inputRadioBox" onChange={handleStatusChange} value="Closed"       checked={checkStatusActive('Closed')}       /> Closed</label><br />
                </div>
              </FormGroup>
              <span className="formValidationError">{statusError}</span>
            </Col>

            <Col lg={6} className="switch__parent">
              <p style={{fontSize:'.83333rem',letterSpacing:'.02em',fontFamily:'Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'}}>
                <i>Please Note:</i> Setting the "Private" switch will remove this Lodge from public view.<br />
                <br />
                This flag is overridden by the Lodge's Status; only Active Lodges will be displayed regardless
                of their "Private" setting. It is not necessary to set the "Private" flag when changing a Lodge 
                from Active status to any other status.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
        <Row align="right">
          <Col lg={12}>
            <Button color="secondary" onClick={props.onClick} id="closeOrganizationModal">Cancel</Button>
            <Button color="primary" onClick={submitOrganizationName} style={{marginLeft:'5px'}}>Submit</Button>
          </Col>
        </Row>
        </ModalFooter>
        { showHideLoader1 ? <CopiriLoader />: ''}
      </Modal>
    </>
  );
}

export default LodgeModal;
