/* eslint react/no-multi-comp: 0, react/prop-types: 0 */// eslint-disable-next-line
import React , { useState,useEffect } from 'react';
// eslint-disable-next-line
import {CardHeader, Row,Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Form,Input,Label,FormGroup,Table,Alert,Media} from 'reactstrap';// eslint-disable-next-line
import {sessionLogout,copiriAxiosGet,copiriPortalRequest,checkPassword} from '../api/api'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import { toast } from 'react-toastify';// eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';// eslint-disable-next-line
import FormGroupInput from '../common/FormGroupInput';// eslint-disable-next-line
import axios from 'axios';// eslint-disable-next-line
import logo from '../../assets/img/logos/logo.png';
import loader from '../../assets/img/loader/small_loader.gif';
import { MultiSelect } from "react-multi-select-component";
import validator from 'validator'
const ProfileModal = (props) => {// eslint-disable-next-line 
  const [name, setName] = useState('');// eslint-disable-next-line
  const [email, setEmail] = useState('');// eslint-disable-next-line
  const [emailError, setEmailError] = useState('');// eslint-disable-next-line
  const [password, setPassword] = useState('');// eslint-disable-next-line
  const [passwordError, setPasswordError] = useState('');// eslint-disable-next-line
  const [honorific, setHonorific] = useState('');// eslint-disable-next-line
  const [confirmPassword, setConfirmPassword] = useState('');// eslint-disable-next-line
  const [confirmPasswordError, setConfirmPasswordError] = useState('');// eslint-disable-next-line
  const [passwordNotMatchError, setPasswordNotMatchError] = useState('');// eslint-disable-next-line
  const [office, setOffice] = useState('');// eslint-disable-next-line
  const [changeFields, setChangeFields] = useState(false);// eslint-disable-next-line
  const [showHideLoader1,setShowHideLoader1] = useState(false);
  const [grandLodgeData, setGrandLodgeData] = useState([]);
  const [showGLData, setShowGLData] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if(props.showHide) {
      getProfileData();      
      setChangeFields(false);
      setSelected([]);
    }
    // eslint-disable-next-line
  }, [props]);

  // function to get profile modal data
  function getProfileData() {
    var login_user_id = localStorage.getItem('login_user_id')  // uuid
    var url = config.elol_api_url+'/users/'+login_user_id;
    copiriPortalRequest(url,config.app_version,'get').then(response => {
      setHonorific(response.data.title)
      setOffice(response.data.office);
      setEmail(response.data.email);
      setName(response.data.name);
      setPassword('');
      setConfirmPassword('');
      //setShowGLData
      if (response.data.access && response.data.access !== undefined && response.data.access !== null) {
        setShowGLData(true);
        getGrandLodgeData(response.data.access);
        /*var arr = []; 
        arr.push( {"value" : "57626781-629a-4e58-9a3b-ff49dcb46f6e"});
        setSelected(arr);*/
      }
    }).catch(error=>{
      if (error.response !==undefined) {
        if (error.response.status===401) {
          toast.error('Your session has expired. Please log in again.');
          sessionLogout();
        }        
        if (error.response.status===404) {
          alert(error.response.data.error);
        }
        if (error.response.status===500) {
          alert(error.response.data.error);
        }
      }
    });
  }

  // function to ger grand lodge data
  function getGrandLodgeData(glAccess) {
    var arr = [];
    var checkArr = [];
    var grandLodges = JSON.parse(localStorage.getItem('recognitionData'));
    if (grandLodges.length >0) {
      for (var i=0;i<grandLodges.length;i++) {
        arr.push( {"label" : grandLodges[i]['name'], "value" : grandLodges[i]['uuid']});

        if (glAccess.includes(grandLodges[i]['uuid'])) {
          checkArr.push( {"label" : grandLodges[i]['name'], "value" : grandLodges[i]['uuid']});
        }
      }
      setSelected(checkArr);
      setGrandLodgeData(arr);
    }

    /*copiriPortalRequest(config.elol_api_url+'/grandlodges?filter=full',config.app_version,'get').then(response => {
      
      if (response.data.grandlodges.length >0 ) {
        for (var i=0;i<response.data.grandlodges.length;i++) {
          arr.push( {"label" : response.data.grandlodges[i]['name'], "value" : response.data.grandlodges[i]['uuid']});

          if (glAccess.includes(response.data.grandlodges[i]['uuid'])) {
            checkArr.push( {"label" : response.data.grandlodges[i]['name'], "value" : response.data.grandlodges[i]['uuid']});
          }
        }
        setSelected(checkArr);
        setGrandLodgeData(arr);
      }
    }).catch(error=>{
    });*/
  }

  /* function to accept the value of password input box */
  const changePassword = event => {
    setPassword(event.target.value)
    setChangeFields(true);
  }

  //onchangeEmail
  /* function to accept the value of email input box */
  const changeEmail = event => {
    setEmail(event.target.value)
    setChangeFields(true);
  }

  /* function to get the value of honorific input box*/
  const changeHonorific = event => {
    setHonorific(event.target.value);
    setChangeFields(true);
  };
 

  /* function to get the value of confirm password input box*/
  const changeConfirmPassword = event => {
    setConfirmPassword(event.target.value);
    setChangeFields(true);
  };

  
  /* function to get the value of change office box*/
  const changeoffice = event => {
    setOffice(event.target.value);
    setChangeFields(true);
  };
    
  /* function to get the value of multiple select box*/
  const changeGrandLodgeAccess = event => {
    setSelected(event);
    setChangeFields(true);
  };

  /* function to request API to update user profile*/
  function updateProfile() { 
    setEmailError('')
    setPasswordError('')
    setConfirmPasswordError('')
    setPasswordNotMatchError('');
    var error = 0;
    // if email is empty
    if (email === '') {
      setEmailError('This field is required');
      error++;
    }

    if (email !== '') {
      if (!validator.isEmail(email)) {
        setEmailError('Please enter valid email');
        error++;
      }
    }

    // if password did not match
    if (!error && (password !=='' ||  confirmPassword !== '')) {
        if (password ==='') {
        setPasswordError('Please enter a password.');
        error++;
      }

      // Confirm that the password confirmation is not empty
      if (!error && confirmPassword ==='') {
        setConfirmPasswordError('Please enter your password a second time.');
        error++;
      }

    }

    // if password did not match
    if (!error && (password !=='' &&  confirmPassword !== '')) {
      var checkPasswordError = checkPassword(password,confirmPassword,error);
      if (checkPasswordError['error']>=1) {
        error++;
        setPasswordNotMatchError(checkPasswordError['msg']);
      }
    }

    if (!error) {
      if (changeFields) {
        var glValues = [];
        if (selected && selected.length>0) {
          for (var i=0;i<selected.length;i++) {
            glValues.push(selected[i]['value']);
          }
        }
        setShowHideLoader1(true);
        var login_user_id = localStorage.getItem('login_user_id')  // uuid
        var url = config.elol_api_url+'/users/'+login_user_id;
        var data = {};
        data['email']    = email; 
        data['title']    = honorific;
        data['office']   = office;
        if (glValues.length>0) {
          data['access'] = (glValues);
// TODO :: The empty array should ONLY be submitted if the logged in user is an admin.
//         Otherwise, the user will clear their access every time they update their profile.
//         } else {
//           data['access'] = [];
        }

        if (password !== '' &&  confirmPassword !== '') {
          data['password'] = password; 
        }

        var token = localStorage.getItem('session_id');
        axios({
          method: 'PATCH', 
          url: url, 
          data:JSON.stringify(data),
          headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type' : 'application/x-www-form-urlencoded',
          }
        }).then(response => {
          setChangeFields(false);
          setShowHideLoader1(false);
          setPassword('');
          setConfirmPassword('');
          if (response.status === 200) {
            toast.success('Success!')
          }
          if (response.status===401) {
            toast.error('Your session has expired. Please log in again.');
            sessionLogout();
          }
          props.onClick();
        }).catch(error=>{
          setPassword('');
          setConfirmPassword('');
          setChangeFields(false);
          setShowHideLoader1(false);
          props.onClick();
          if (error.response !==undefined) {
            if (error.response.status===401) {
              toast.error('Your session has expired. Please log in again.');
              sessionLogout();
            } else {
              alert('Error loading user.');
            }
          }
          
          
        });


      }      
    }
  }

  return (
    <>
      <Modal isOpen={props.showHide} toggle={props.onClick} size="" id="profileModal">
        <ModalHeader toggle={props.onClick}>Update Profile</ModalHeader>
        <ModalBody >
          <div className="form-group">

          <FormGroupInput
            id="name"
            label=""
            value={name}
            disabled
            type="text"
            style={{'marginTop':'-23px'}}
          />
          <p style={{fontSize:'12px','marginTop':'-10px'}}>Please contact us at <a href="mailto:grsecconf@ingrandlodge.org." rel="nopener"><span>grsecconf@ingrandlodge.org.</span> </a>to change your name if it is not correct.</p>

            <label htmlFor="honorific" className="">Change Your Honorific</label>
            <select 
              className  = "form-control"  
              onChange   = {changeHonorific}
              value      = {honorific}
              id         = "selecthonorific"
              >
              <option value="">Choose</option>
              <option value="WB">WB</option>
              <option value="VWB">VWB</option>
              <option value="RWB">RWB</option>
              <option value="RRB">RRB</option>
              <option value="RHB">RHB</option>
              <option value="MWB">MWB</option>
              <option value="DB">DB</option>
              <option value="Bro">Bro.</option>
            </select>
          </div>

          <FormGroupInput
            id="office"
            label="Change Your Office"
            value={office}
            onChange={changeoffice}
            type="text"
          />
          
          <FormGroupInput
            id="email"
            label="Change Your Email Address"
            value={email}
            onChange={changeEmail}
            type="email"
            
          />
          
          <span className="formValidationError">{emailError}</span>
          <FormGroupInput
            id="password"
            label="Change Your Password (Optional)"
            value={password}
            onChange={changePassword}
            type="password"
            autoComplete="off"
          />
          <span className="formValidationError">{passwordError}</span>
          <FormGroupInput
            id="confirm-password"
            label="Confirm Your New Password (Optional)"
            value={confirmPassword}
            onChange={changeConfirmPassword}
            type="password"
          />
          <span className="formValidationError">{confirmPasswordError}</span>
          <span className="formValidationError">{passwordNotMatchError}</span>
          <Row style={{marginTop:'22px'}}>
            <Col lg={12}>
              <div className="alert alert-primary" role="alert">
                <i className="far fa-exclamation-circle"></i>
                <span style={{marginLeft:'5px',fontSize:'12px'}}>New passwords must be 10-100 characters and contain at least one letter; at least one number; and at least one special character.</span>
              </div>
            </Col>
          </Row>
          { showGLData &&
            <div className="form-group">
              <label htmlFor="userAccess" className="">Set Grand Lodge Access</label>
              <MultiSelect
                options={grandLodgeData}
                value={selected}
                onChange={changeGrandLodgeAccess}
                labelledBy={"Select"}
                disableSearch
                ClearSelectedIcon
              />
            </div>
          }
          
        </ModalBody>
        <ModalFooter>
          <Col lg={12} style={{textAlign:'right'}}>
            { showHideLoader1 ? 
              <Button color="primary"  style={{color:'#337ab7',width:'21%',padding:'.312rem,1rem'}}>
                 <img src={loader} alt={loader} width="20" height="20"/>
                </Button>
              :
              <Button color="primary"  onClick={updateProfile}>
               Update
              </Button>
            }
          </Col>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ProfileModal;
