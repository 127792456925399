import axios from 'axios';

export const copiriFetch = async (url, data = {}, headers = {},method = 'POST') => {
	return fetch(url, {
		method: 'POST',
		credentials: "same-origin",
		body: data,
	});
};

export const copiriAxiosPost = async (url, data = {}) => {
	return await axios.post(url, data, {
		withCredentials : true,
		headers: {
			'Content-Type' : 'application/json',
		},
	});
};

export const copiriPortalRequest = async (url,app_version,method) => {
	var token = localStorage.getItem('session_id');
	if(token && token!== 'undefined') {
		return await axios({
			method: method, 
			url: url, 
			headers: {
				'Authorization': 'Bearer '+token,
				'Content-Type' : 'application/x-www-form-urlencoded',
			}
		});
	}
};


export const copiriPortalRequestWithoutToken = async (url,app_version,method) => {
		return await axios({
			method: method, 
			url: url, 
			headers: {
				'Content-Type' : 'application/x-www-form-urlencoded',
			}
		});
};

export const copiriAxiosGet = async (url, ) => {
	return await axios.get(url, {
		headers: {
			'Content-Type' : 'application/json',
		},
	});
};

export function checkPassword(password,confirmPassword,error ){
	var data = []
	data['msg'] = '';
	data['error'] = 0;
	var checkError= 0;
	// Confirm that the passwords are not less than 10 characters
	if (!checkError && (password.length < 10 || confirmPassword.length < 10)) {
		data['msg'] = 'Your password must be at least 10 characters.';
		checkError++;
	}

	// Confirm that the passwords are not more than 100 characters
	if (!checkError && (password.length > 100 || confirmPassword.length > 100)) {
		data['msg'] = 'Your password cannot be longer than 100 characters.';
		checkError++;
	}

	// Confirm that the passwords contain at least one letter; one number; and one special character
	if (!checkError) {
		var pattern = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d!@#$%^&*()_-]{8,}$/);	
		if (!pattern.test(password) || !pattern.test(confirmPassword)) {
			data['msg'] = 'Your password must contain at least one letter, one number, and one special character.';
			checkError++;
		}
	}

	// Finally, confirm that the passwords match
	if (!checkError && (password !== confirmPassword)) {
		data['msg'] = 'Your passwords do not match.';
		checkError++;
	}
	data['error'] = checkError;
	return data;
};

export const sessionLogout = async () => {
	localStorage.removeItem('session_id');
	var redirectURl = '';
	if (window.location.host !== 'localhost:9876') {
		redirectURl = "https://" + window.location.host + "/";
	} else {
		redirectURl = "http://" + window.location.host + "/";
	}
	setTimeout(
		() => window.location.href=redirectURl,
		500
	);
};

