import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical'; // eslint-disable-next-line
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import { getPageName } from '../helpers/utils'; // eslint-disable-next-line
import { copiriAxiosPost, sessionLogout,copiriPortalRequest } from '../components/api/api'; // eslint-disable-next-line
import config from '../components/config/config'; // eslint-disable-next-line
import Loader from '../components/common/Loader';
import { toast } from 'react-toastify'; // eslint-disable-next-line
import settings from '../config'; // eslint-disable-next-line
import { useHistory } from 'react-router-dom';
const DashboardRoutes = loadable(() => import('./DashboardRoutes'));
const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical } = useContext(AppContext); // eslint-disable-next-line
  const [checkSession,setCheckSession] = useState(false);// eslint-disable-next-line
  const [showHideLoader,setShowHideLoader] = useState(false);
  const isKanban = getPageName('kanban');
  const history = useHistory();
  useEffect(() => {
    DashboardRoutes.preload();
    checkSessionLogout();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  /* function to check session*/  
  function checkSessionLogout() {
    var sessionId = localStorage.getItem('session_id')
    if(sessionId && sessionId !=='undefined') {
      copiriPortalRequest(config.elol_api_url+'/sessions',config.app_version,'get').then(response => {
        if (response.status===200) {
          setCheckSession(true);
        } else if (response.status===401) {
          toast.error('Your session is expired. Please log in again');
          localStorage.setItem('session_id','');
          sessionLogout();
        }
      }).catch(error=>{
        if (error.response.status===401) {
          toast.error('Your session is expired. Please log in again');
          localStorage.setItem('session_id','');
          sessionLogout();
        }

        if (error.response.status===500) {
          alert('Something went wrong!  Please reload this page and try again.');
        }
      });
    } else {
      localStorage.setItem('last_url_location', history.location.pathname);// eslint-disable-next-line
      history.push('/');
    }
  }

  return (
    <>
  {
    checkSession ?
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={settings.settings.navbarStyle} />}
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/home" exact component={Dashboard} />
            <DashboardRoutes />
          </Switch>
          <Footer />
        </div>
      </ProductProvider>
    </div>
  : <div className="sessionLoadingIcon"><Loader /></div>
}
</>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
