/* eslint react/no-multi-comp: 0, react/prop-types: 0 */// eslint-disable-next-line
import React , { useState,useEffect } from 'react';
// eslint-disable-next-line
import {CardHeader, Row,Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Form,Input,Label,FormGroup,Table,Alert,Media} from 'reactstrap';// eslint-disable-next-line
import {sessionLogout,copiriAxiosGet,copiriPortalRequest,checkPassword} from '../api/api'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import { toast } from 'react-toastify';// eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';// eslint-disable-next-line
import FormGroupInput from '../common/FormGroupInput';// eslint-disable-next-line
import axios from 'axios';// eslint-disable-next-line
import logo from '../../assets/img/logos/logo.png';
const UpdatePasswordPopup = (props) => {// eslint-disable-next-line
	/* function to request API to update security popup*/
	const [password, setPassword] = useState('');// eslint-disable-next-line
	const [passwordError, setPasswordError] = useState('');// eslint-disable-next-line
	const [confirmPassword, setConfirmPassword] = useState('');// eslint-disable-next-line
	const [confirmPasswordError, setConfirmPasswordError] = useState('');// eslint-disable-next-line
	const [passwordNotMatchError, setPasswordNotMatchError] = useState('');// eslint-disable-next-line
	const [changeFields, setChangeFields] = useState(false);// eslint-disable-next-line
	
	/* function to accept the value of password input box */
	const changePassword = event => {
		setPassword(event.target.value)
		setChangeFields(true);
	}
	
	/* function to get the value of confirm password input box*/
	const changeConfirmPassword = event => {
		setConfirmPassword(event.target.value);
		setChangeFields(true);
	};
	
	/* function to update password */
	function UpdatePassword() {
		setPasswordError('')
		setConfirmPasswordError('')
		setPasswordNotMatchError('');
		var error = 0;
		
		// Confirm that the password is not empty
		if (password ==='') {
			setPasswordError('Please enter a password.');
			error++;
		}
		
		// Confirm that the password confirmation is not empty
		if (!error && confirmPassword ==='') {
			setConfirmPasswordError('Please enter your password a second time.');
			error++;
		}

		// check for password and confirm password fields
	    if (!error && password !=='' &&  confirmPassword !== '') {
	      var checkPasswordError = checkPassword(password,confirmPassword,error);
	      if (checkPasswordError['error']>=1) {
	        error++;
	        setPasswordNotMatchError(checkPasswordError['msg']);
	      }
	    }
		
		if (!error) {
			if (changeFields) {
				var login_user_id = localStorage.getItem('login_user_id')  // uuid
				var url = config.elol_api_url+'/users/'+login_user_id;
				var token = localStorage.getItem('session_id');
				var data = {};
				
				if (password !== '') {
					data['password'] = password; 
				}
				
				axios({
					method: 'PATCH', 
					url: url, 
					data:(data),
					headers: {
						'Authorization': 'Bearer '+token,
						'Content-Type' : 'application/json',
					}
				}).then(response => {
					setChangeFields(false);
					
					if (response.status === 200) {
						toast.success('Success!')
					}
					
					if (response.status===401) {
						toast.error('Your session has expired. Please log in again.');
						sessionLogout();
					}
					
					props.onClosePaswordModal();
				}).catch(error=>{
					setChangeFields(false);
					
					if (error.response !==undefined) {
						if (error.response.status===401) {
							toast.error('Your session has expired. Please log in again.');
							sessionLogout();
						} else {
							alert(error.response.data.error);
						}
					}
					
					props.onClosePaswordModal();
				});
			}
		}
	}
	
	return (
		<>
		<Modal isOpen={props.showHide}  size="" id="" >
			<ModalBody >
				<p>Please Choose a New Password</p>
				<Row style={{marginTop:'22px'}}>
		            <Col lg={12}>
		              <div className="alert alert-primary" role="alert">
		                <i className="far fa-exclamation-circle"></i>
		                <span style={{marginLeft:'5px',fontSize:'12px'}}>New passwords must be 10-100 characters and contain at least one letter; at least one number; and at least one special character.</span>
		              </div>
		            </Col>
		          </Row>
				<FormGroupInput
					id="password"
					label="Change Your Password"
					value={password}
					onChange={changePassword}
					type="password"
					autoComplete="off"
				/>
				<span className="formValidationError">{passwordError}</span>
				<span className="formValidationError">{passwordNotMatchError}</span>
				<FormGroupInput
					id="confirm-password"
					label="Confirm Your New Password"
					value={confirmPassword}
					onChange={changeConfirmPassword}
					type="password"
				/>
				<span className="formValidationError">{confirmPasswordError}</span>
			</ModalBody>
			<ModalFooter>
				<Col lg={12} style={{textAlign:'center'}}>
					<Button color="primary" style={{marginLeft:'20px'}} onClick={UpdatePassword}>Submit</Button>
				</Col>
			</ModalFooter>
		</Modal>
		</>
	);
}

export default UpdatePasswordPopup;
