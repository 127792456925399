import React from 'react';
import { Col, Row } from 'reactstrap';
import config from '../config/config';
const Footer = () => {
  var today = new Date(),
  date = today.getFullYear();
  return(
    <footer>
      <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            Powered by Copiri | © {date}
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">v{config.site_version}</p>
        </Col>
      </Row>
    </footer>
  );
}
export default Footer;
