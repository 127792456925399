import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import loadable from '@loadable/component';
const PreLogin = loadable(() => import('../components/prelogin/PreLogin'));
const Logout = loadable(() => import('../components/logout/Logout'));

const Layout = () => {
  useEffect(() => {
    PreLogin.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={PreLogin} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
