import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';
const NavbarVerticalMenuItem = ({ route }) => {
  return(
  <Flex align="center">
    {route.icon && (
      <span className="nav-link-icon">
      <i className={route.icon}></i>
      </span>
    )}
    <span className="nav-link-text">{route.name}</span>
    {!!route.badge && (
      <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
        {route.badge.text}
      </Badge>
    )}
  </Flex>
  );
};

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired
  }).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
