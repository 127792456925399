/* eslint react/no-multi-comp: 0, react/prop-types: 0 */// eslint-disable-next-line
import React , { useState,useEffect } from 'react';
// eslint-disable-next-line
import {CardHeader, Row,Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Form,Input,Label,FormGroup,Table,Alert,Media} from 'reactstrap';// eslint-disable-next-line
import {sessionLogout,copiriAxiosGet,copiriPortalRequest} from '../api/api'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import { toast } from 'react-toastify';// eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';// eslint-disable-next-line
import FormGroupInput from '../common/FormGroupInput';// eslint-disable-next-line
import axios from 'axios';// eslint-disable-next-line
import Datetime from 'react-datetime';
import validator from 'validator'
const GrandLodgeModal = (props) => {// eslint-disable-next-line
  const [showHideLoader1,setShowHideLoader1] = useState(false);
  const [name,setName] = useState('');
  const [gmHonorific, setGMHonorific] = useState('');
  const [gsHonorific, setGSHonorific] = useState('');
  const [frHonorific, setFRHonorific] = useState('');// eslint-disable-next-line
  const [formationDate,setformationDate] = useState('');
  const [meetingSchedule,setmeetingSchedule] = useState('');
  const [meetingScheduleError,setmeetingScheduleError] = useState('');
  const [GMName,setGMName] = useState('');
  const [GMNameError,setGMNameError] = useState('');
  const [GSName,setGSName] = useState('');
  const [GSNameError,setGSNameError] = useState('');
  const [FRName,setFRName] = useState('');
  const [FRPhone,setFRPhone] = useState('');
  const [FREmail,setFREmail] = useState('');
  const [FRRole,setFRRole] = useState('');
  const [facility,setFacility] = useState('');
  const [facilityError,setFacilityError] = useState('');
  const [address1,setAddress1] = useState('');// eslint-disable-next-line
  const [address2,setAddress2] = useState('');// eslint-disable-next-line
  const [address3,setAddress3] = useState('');// eslint-disable-next-line
  const [postcode,setPostcode] = useState('');// eslint-disable-next-line
  const [postcodeError,setPostcodeError] = useState('');
  const [city,setCity] = useState('');// eslint-disable-next-line
  const [cityError,setCityError] = useState('');// eslint-disable-next-line
  const [country,setCountry] = useState('');// eslint-disable-next-line
  const [countryError,setCountryError] = useState('');// eslint-disable-next-line
  const [state,setState] = useState('');// eslint-disable-next-line
  const [countriesData,setCountriesData] = useState([]); // eslint-disable-next-line
  const [stateData,setStateData] = useState([]); // eslint-disable-next-line
  const [checkStateData,setCheckStateData] = useState(false);// eslint-disable-next-line
  const [state2, setState2] = useState('');// eslint-disable-next-line
  const [phone, setPhone] = useState('');// eslint-disable-next-line
  const [phoneError, setPhoneError] = useState('');// eslint-disable-next-line
  const [email, setEmail] = useState('');// eslint-disable-next-line
  const [emailError, setEmailError] = useState('');// eslint-disable-next-line
  const [website, setWebsite] = useState('');// eslint-disable-next-line
  const [websiteError, setWebsiteError] = useState('');// eslint-disable-next-line
  const [date, setDate] = useState(new Date());
  const [id,setId] = useState('');// eslint-disable-next-line
  const [checkInputChange,setCheckInputChange] = useState(false);// eslint-disable-next-line

  const [checkPrivateBasicInfo,setCheckPrivateBasicInfo] = useState(false);
  const [checkPrivateGrandMaster,setCheckPrivateGrandMaster] = useState(false);
  const [checkPrivateGrandSecretary,setCheckPrivateGrandSecretary] = useState(false);
  const [checkPrivateForeignRelation,setCheckPrivateForeignRelation] = useState(false);
  const [checkPrivateAddress,setCheckPrivateAddress] = useState(false);
  const [checkPrivateOtherContactInfo,setCheckPrivateOtherContactInfo] = useState(false);
  const [checkPrivateAllData,setCheckPrivateAllData] = useState(false);
  const [dataPublic,setDataPublic] = useState(false);
  const [isDataPublic,setIsDataPublic] = useState(false);

  /* initial api request to get the lodge data*/
  useEffect(() => {                           // side effect hook
    if (props.id && props.showHide) {
      var checkCData = (localStorage.getItem('countriesData'));
      setCountriesData(JSON.parse(checkCData));
      setId(props.grandLodgeData.headerData.uuid)
      setName(props.grandLodgeData.headerData.name)
      setformationDate(props.grandLodgeData.headerData.formed_date);
      setmeetingSchedule(props.grandLodgeData.headerData.communication);
      
      setGMHonorific(props.grandLodgeData.headerData.gm_title);
      setGMName(props.grandLodgeData.headerData.gm_name);
      
      setGSHonorific(props.grandLodgeData.headerData.gs_title);
      setGSName(props.grandLodgeData.headerData.gs_name);
      
      setFRHonorific(props.grandLodgeData.headerData.fr_title);
      setFRName(props.grandLodgeData.headerData.fr_name);
      setFRRole(props.grandLodgeData.headerData.fr_office);
      setFRPhone(props.grandLodgeData.headerData.fr_phone);
      setFREmail(props.grandLodgeData.headerData.fr_email);

      if(props.grandLodgeData.headerData.formed_date){
        var calDate = props.grandLodgeData.headerData.formed_date.split('-');
        var mydate = new Date(calDate['0']+'-'+calDate['1']+'-'+calDate['2']);
        setDate(mydate);  
      }

      setFacility(props.grandLodgeData.headerData.facility)
      setAddress1(props.grandLodgeData.headerData.street_1)
      setAddress2(props.grandLodgeData.headerData.street_2);
      setAddress3(props.grandLodgeData.headerData.street_3);
      
      if (props.grandLodgeData.headerData.city && props.grandLodgeData.headerData.city !=='') {
        setCity(props.grandLodgeData.headerData.city);
      }

      setCountry(props.grandLodgeData.headerData.country_id);
      getStatesData(props.grandLodgeData.headerData.country_id,props.grandLodgeData.headerData.state_id,props.grandLodgeData.headerData.state)
      setPhone(props.grandLodgeData.headerData.phone);
      setEmail(props.grandLodgeData.headerData.email);
      setWebsite(props.grandLodgeData.headerData.website);
      setPostcode(props.grandLodgeData.headerData.postcode);

      if (props.grandLodgeData.headerData.private_basic ===true) {
        setCheckPrivateBasicInfo(true);
      }

      if (props.grandLodgeData.headerData.private_gm ===true) {
        setCheckPrivateGrandMaster(true);
      }

      if (props.grandLodgeData.headerData.private_gs ===true) {
        setCheckPrivateGrandSecretary(true);
      }

      if (props.grandLodgeData.headerData.private_fr ===true) {
        setCheckPrivateForeignRelation(true);
      }

      if (props.grandLodgeData.headerData.private_address ===true) {
        setCheckPrivateAddress(true);
      }

      if (props.grandLodgeData.headerData.private_other ===true) {
        setCheckPrivateOtherContactInfo(true);
      }

      if (props.grandLodgeData.headerData.private_all ===true) {
        setCheckPrivateAllData(true);
      }
      setIsDataPublic(false);
    }
    
    // eslint-disable-next-line
  }, [props])
  const handleOnChange = (e, type) => {
    setCheckInputChange(true);
    if (type === 'formationDate') {
      setformationDate(e.target.value);
    }

    if (type === 'meetingSchedule') {
      setmeetingSchedule(e.target.value);
    }

    if (type === 'GMName') {
      setGMName(e.target.value);
    }

    if (type === 'GSName') {
      setGSName(e.target.value);
    }

    if (type === 'FRName') {
      setFRName(e.target.value);
    }

    if (type === 'FRPhone') {
      setFRPhone(e.target.value);
    }

    if (type === 'FREmail') {
      setFREmail(e.target.value);
    }

    if (type === 'FRRole') {
      setFRRole(e.target.value);
    }

    if (type === 'facility') {
      setFacility(e.target.value);
    }

    if (type === 'address1') {
      setAddress1(e.target.value);
    }

    if (type === 'address2') {
      setAddress2(e.target.value);
    }

    if (type === 'address3') {
      setAddress3(e.target.value);
    }

    if (type === 'city') {
      setCity(e.target.value);
    }

    if (type === 'postcode') {
      setPostcode(e.target.value);
    }

    if (type === 'country') {
      if (e.target.value !=='') {
        setCountry(e.target.value);
        getStatesData(e.target.value,'');
        setState2('');
      } else {
        setCountry(e.target.value);
        setStateData([]);
        setState2('');
      }
    }

    if (type === 'state') {
      setState(e.target.value);
    }

    if (type === 'state2') {
      setState2(e.target.value);
    }

    if (type === 'email') {
      setEmail(e.target.value);
    }

    if (type === 'phone') {
      setPhone(e.target.value);
    }

    if (type === 'website') {
      setWebsite(e.target.value);
    }
  }

  /* function to get the states Data based on country id */
  function getStatesData(country_id,state_id,stateName) {
    //setShowHideLoader1(true);
      copiriPortalRequest(config.elol_api_url+'/lists/countries/'+country_id+'/states',config.app_version,'get').then(response => {
      if (response.data.length>0) {
        setStateData(response.data);
        setCheckStateData(true);
        setState(state_id);  
      } else {
        setStateData([]);
        setCheckStateData(false);
        setState2(stateName);
      }
    }).catch(error=>{
    });
  }

  /* function to check the value of input box and set leave page attribute true */
  const onChangeDate = event => {
    setDate(new Date(event));
    setCheckInputChange(true);
  };

  /* function to set private flag to Grand Master */
  const handleBasicInfoSwitchChange = event =>  {
    setCheckPrivateBasicInfo(!checkPrivateBasicInfo);
    setCheckInputChange(true);
  }

  /* function to set private flag to Grand Master */
  const handleGMSwitchChange = event =>  {
    setCheckPrivateGrandMaster(!checkPrivateGrandMaster);
    setCheckInputChange(true);
  }

  /* function to set private flag to Grand Secretary */
  const handleGSSwitchChange = event =>  {
    setCheckPrivateGrandSecretary(!checkPrivateGrandSecretary);
    setCheckInputChange(true);
  }

  
  /* function to set private flag to Foreign Relation */
  const handleFRSwitchChange = event =>  {
    setCheckPrivateForeignRelation(!checkPrivateForeignRelation);
    setCheckInputChange(true);
  }

  /* function to set private flag to Address */
  const handleAddressSwitchChange = event =>  {
    setCheckPrivateAddress(!checkPrivateAddress);
    setCheckInputChange(true);
  }

  /* function to set private flag to Other Contact Info */
  const handleContactInfoSwitchChange = event =>  {
    setCheckPrivateOtherContactInfo(!checkPrivateOtherContactInfo);
    setCheckInputChange(true);
  }

  /* function to set private flag to all Data */
  const handleAllDataSwitchChange = event =>  {
    setCheckPrivateAllData(!checkPrivateAllData);
    
    if (!checkPrivateAllData) {
      setIsDataPublic(true);
    } else {
      setIsDataPublic(false);
      setDataPublic(false);
    }
    setCheckInputChange(true);
  }

  /* function to make sure all data public */
  const visibleAllDataPublic = event =>  {
    setDataPublic(!dataPublic);
  }

  /* function to request API to Submit Grand Lodge Header Data*/
  function submitGrandLodgeHeader() {
    setGMNameError('')
    setGSNameError('');
    setCityError('');
    setFacilityError('');
    setCountryError('');
    setEmailError('');
    setWebsiteError('');
    setmeetingScheduleError('');
    setPhoneError();
    var error = 0;

    if (city === '') {
      setCityError('This field is required');
      error++;
    }
    
    if (country === '') {
      setCountryError('This field is required');
      error++;
    }
    
    if (email && email !== '') {
      if (!validator.isEmail(email)) {
        setEmailError('Please enter a valid email address.')
        error++;
      } 
    }
    
    if (website && website !== '') {
      if (!validator.isURL(website)) {
        setWebsiteError('Please enter a valid URL.')
        error++;
      } 
    }

    if (isDataPublic === true) {
      if (dataPublic !== true) {
        alert('Please confirm that you understand that you have chosen to make your Grand Lodge\'s data public to anyone on the internet.');
        error++;
      }
    }
    
    if (!error) {
      if (checkInputChange) {        
        setShowHideLoader1(true);        
        var token = localStorage.getItem('session_id');
        var data = {}; // array to send data in json
        var gm_data = {}; // array to take Grand Manager field values
        var gs_data = {}; // array to take Grand Manager field values
        var fr_data = {}; // array to take Foreign Relations field values
        var addressFields = {}; // array to take the address filed values
        var year = date.getFullYear();
        var month = (date.getMonth()+1);
        var day = date.getDate();
        
        if (month < 10) {
          month= "0" + month;
        }

        if (day < 10) {
          day= "0" + day;
        }
        
        var formation_date = year + '-' + month + '-' + day;
        data['formation_date'] = formation_date;
        data['schedule'] = meetingSchedule;

        gm_data['title'] = gmHonorific;
        gm_data['name'] = GMName;
        data['grand_master'] = gm_data;

        gs_data['title'] = gsHonorific;
        gs_data['name'] = GSName;
        data['grand_secretary'] = gs_data;

        fr_data['title'] = frHonorific;
        fr_data['name'] = FRName;
        fr_data['office'] = FRRole
        fr_data['phone'] = FRPhone;
        fr_data['email'] = FREmail;
        data['foreign_relations'] = fr_data

        var state_name = '';
        var state_id = '';
        
        if (!checkStateData) {
          state_name = state2;
        } 
        
        if (checkStateData) {
          if (state !=='') {
            state_id = state
          }
          
        }

        addressFields['facility']       = facility;
        addressFields['street_one']     = address1;
        addressFields['street_two']     = address2;
        addressFields['street_three']   = address3;
        addressFields['city']           = city;
        addressFields['state_id']       = state_id.toString();
        addressFields['state']          = state_name;
        addressFields['postcode']       = postcode;
        addressFields['country']        = country;
        data['address']                 = addressFields;
        data['phone']                   = phone;
        data['email']                   = email;
        data['website']                 = website;
    	data['private_basic']           = false;
    	data['private_gm']              = false;
    	data['private_gs']              = false; 
    	data['private_fr']              = false; 
    	data['private_address']         = false; 
    	data['private_other']           = false; 
    	data['private_all']             = false; 
        
        if (checkPrivateBasicInfo === true) {
          data['private_basic'] = true;
        }

        if (checkPrivateGrandMaster === true) {
          data['private_gm'] = true;
        }

        if (checkPrivateGrandSecretary === true) {
          data['private_gs'] = true; 
        }

        if (checkPrivateForeignRelation === true) {
          data['private_fr'] = true; 
        }

        if (checkPrivateAddress === true) {
          data['private_address'] = true; 
        }

        if (checkPrivateOtherContactInfo === true) {
          data['private_other'] = true; 
        }

        if (checkPrivateAllData === true) {
          data['private_all'] = true; 
        }
        
        axios({
          method: 'put', 
          url: config.elol_api_url+'/grandlodges/'+id, 
          data:data,
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type' : 'application/x-www-form-urlencoded',
          }
        }).then(response => {          
          if (response.status===200) {
            toast.success('Success!')
          }

          if (response.status===401) {
            toast.error('Your session has expired. Please log in again.');
            sessionLogout();
          }
          
          setShowHideLoader1(false);
          props.onClick();
          props.updateGrandLodgeData();
        }).catch(error=>{
          if (error.response !==undefined) {
            if (error.response.status===401) {
              toast.error('Your session has expired. Please log in again.');
              sessionLogout();
            }
            
            if (error.response.status===404) {
              alert(error.response.data.error);
            }
          }
          
          setShowHideLoader1(false);
          props.onClick();
          props.updateGrandLodgeData()
        });
      }
    }
  }

  return (
    <>
      <Modal isOpen={props.showHide} toggle={props.onClick} size="lg" id="grnadLodgeHeaderModal">
      <ModalHeader toggle={props.onClick}>{name}</ModalHeader>
        <ModalBody>
          <Row>
			<Col lg={9} style={{fontWeight: 'bold', marginTop: '0px'}}>Basic Information</Col>
			<Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '0px'}}>
			  <div style={{float: 'right', marginRight: '20%'}}>
			    <label className="switch">
				  <input type="checkbox"  onChange={handleBasicInfoSwitchChange} checked={checkPrivateBasicInfo}/>
				  <span className="slider round"></span>
				</label>
                <Label for="exampleName" className="mr-2">Private?</Label>
			  </div>
			</Col>
          </Row>
            
          <Row>
            <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          <Row>
            <Col lg={6}>
              <Label for="exampleText" className="font-weight-bold">Formation Date:</Label>
              <Datetime dateFormat="DD/MM/YYYY" 
                  timeFormat={false} 
                  value ={date} 
                  className="datetime" 
                  onChange={onChangeDate}
                  id="dx245762_date"
              />
            </Col>

            <Col lg={6}>
              <Label for="exampleText" className="font-weight-bold">Meeting Schedule:</Label>
              <Input
                  type="text"
                  name="text"
                  id="meetingSchedule"
                  placeholder="Meeting Schedule"
                  value={meetingSchedule}
                  onChange={(e) => handleOnChange(e, 'meetingSchedule')}
              />
              <span className="formError">{meetingScheduleError}</span>
            </Col>
          </Row>
            
          <Row>
            <Col lg={9} style={{fontWeight: 'bold', marginTop: '30px'}}>Grand Master</Col>
            <Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '30px'}}>
              <div style={{float: 'right', marginRight: '20%'}}>
                <label className="switch">
                  <input type="checkbox"  onChange={handleGMSwitchChange} checked={checkPrivateGrandMaster}/>
                  <span className="slider round"></span>
                </label>
                <Label for="exampleName" className="mr-2">Private?</Label>
              </div>
            </Col>
          </Row>
            
          <Row>
              <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          <Row>
              <Col lg={4}>
                <Label for="exampleText" className="font-weight-bold">Honorific:</Label>
                <select className="form-control" value={gmHonorific}
                  onChange={({ target }) => setGMHonorific(target.value)}
                >
                  <option value="select">Choose</option>
                  <option value="WB">WB</option>
                  <option value="VWB">VWB</option>
                  <option value="RWB">RWB</option>
                  <option value="RRB">RRB</option>
                  <option value="RHB">RHB</option>
                  <option value="MWB">MWB</option>
                  <option value="DB">DB</option>
                  <option value="Bro">Bro.</option>
                </select>
              </Col>

              <Col lg={8}>
                <Label for="exampleText" className="font-weight-bold">Name:</Label>
                <Input
                  type="text"
                  name="text"
                  id="GMName"
                  placeholder="Grand Master's Name"
                  value={GMName}
                  onChange={(e) => handleOnChange(e, 'GMName')}
                />
                <span className="formError">{GMNameError}</span>
              </Col>
          </Row>
            
          <Row>
              <Col lg={9} style={{fontWeight: 'bold', marginTop: '30px'}}>Grand Secretary</Col>
              <Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '30px'}}>
                <div style={{float: 'right', marginRight: '20%'}}>
                  <label className="switch">
                    <input type="checkbox"  onChange={handleGSSwitchChange} checked={checkPrivateGrandSecretary}/>
                    <span className="slider round"></span>
                  </label>
                  <Label for="exampleName" className="mr-2">Private?</Label>
                </div>
              </Col>
          </Row>
            
          <Row>
              <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          <Row>
              <Col lg={4}>
                <Label for="exampleText" className="font-weight-bold">Honorific:</Label>
                <select className="form-control" value={gsHonorific}
                  onChange={({ target }) => setGSHonorific(target.value)}
                >
                  <option value="select">Choose</option>
                  <option value="WB">WB</option>
                  <option value="VWB">VWB</option>
                  <option value="RWB">RWB</option>
                  <option value="RRB">RRB</option>
                  <option value="RHB">RHB</option>
                  <option value="MWB">MWB</option>
                  <option value="DB">DB</option>
                  <option value="Bro">Bro.</option>
                </select>
              </Col>

              <Col lg={8}>
                <Label for="exampleText" className="font-weight-bold">Name:</Label>
                <Input
                  type="text"
                  name="text"
                  id="GSName"
                  placeholder="Grand Secretary's Name"
                  value={GSName}
                  onChange={(e) => handleOnChange(e, 'GSName')}
                />
                <span className="formError">{GSNameError}</span>
              </Col>
          </Row>
            
          <Row>
              <Col lg={9} style={{fontWeight: 'bold', marginTop: '30px'}}>Foreign Relations POC</Col>
              <Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '30px'}}>
                <div style={{float: 'right', marginRight: '20%'}}>
                  <label className="switch">
                    <input type="checkbox"  onChange={handleFRSwitchChange} checked={checkPrivateForeignRelation}/>
                    <span className="slider round"></span>
                  </label>
                  <Label for="exampleName" className="mr-2">Private?</Label>
                </div>
              </Col>
          </Row>
            
          <Row>
              <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          <Row>
            <Col lg={12}>
              <div className="alert alert-warning" role="alert">
                <i className="fas fa-exclamation-triangle"></i> <b>Note:</b> If this section is not completed, the Grand Secretary's information will be used.
              </div>
            </Col>
          </Row>
            
          <Row>
              <Col lg={3}>
                <Label for="exampleText" className="font-weight-bold">Honorific:</Label>
                <select className="form-control" value={frHonorific}
                  onChange={({ target }) => setFRHonorific(target.value)}
                >
                  <option value="select">Choose</option>
                  <option value="WB">WB</option>
                  <option value="VWB">VWB</option>
                  <option value="RWB">RWB</option>
                  <option value="RRB">RRB</option>
                  <option value="RHB">RHB</option>
                  <option value="MWB">MWB</option>
                  <option value="DB">DB</option>
                  <option value="Bro">Bro.</option>
                </select>
              </Col>

              <Col lg={5}>
                <Label for="exampleText" className="font-weight-bold">Name:</Label>
                <Input
                  type="text"
                  name="text"
                  id="FRName"
                  placeholder="Foreign Relations POC's Name"
                  value={FRName}
                  onChange={(e) => handleOnChange(e, 'FRName')}
                />
              </Col>

              <Col lg={4}>
                <Label for="exampleText" className="font-weight-bold">Office:</Label>
                <Input
                  type="text"
                  name="text"
                  id="FRRole"
                  placeholder="Office"
                  value={FRRole}
                  onChange={(e) => handleOnChange(e, 'FRRole')}
                />
              </Col>
          </Row>
            
          <Row>
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Phone Number:</Label>
                <Input
                  type="text"
                  name="text"
                  id="FRPhone"
                  placeholder="Foreign Relations POC's Phone"
                  value={FRPhone}
                  onChange={(e) => handleOnChange(e, 'FRPhone')}
                />
              </Col>

              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Email Address:</Label>
                <Input
                  type="text"
                  name="text"
                  id="FREmail"
                  placeholder="Foreign Relations POC's Email"
                  value={FREmail}
                  onChange={(e) => handleOnChange(e, 'FREmail')}
                />
              </Col>
          </Row>
            
          <Row>
              <Col lg={9} style={{fontWeight: 'bold', marginTop: '30px'}}>Grand Lodge Address</Col>
              <Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '30px'}}>
                <div style={{float: 'right', marginRight: '20%'}}>
                  <label className="switch">
                    <input type="checkbox"  onChange={handleAddressSwitchChange} checked={checkPrivateAddress}/>
                    <span className="slider round"></span>
                  </label>
                  <Label for="exampleName" className="mr-2">Private?</Label>
                </div>
              </Col>
          </Row>
            
          <Row>
              <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          <Row>
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Building Name:</Label>
                <Input
                  type="text"
                  name="facility"
                  id="facility"
                  placeholder="Building Name"
                  value={facility}
                  onChange={(e) => handleOnChange(e, 'facility')}
                />
                <span className="formError">{facilityError}</span>
              </Col>

              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Street Address (First Line):</Label>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Street (First Line)"
                  value={address1}
                  onChange={(e) => handleOnChange(e, 'address1')}
                />
              </Col>
          </Row>
            
          <Row>
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Street Address (Second Line):</Label>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Street (Second Line)"
                  value={address2}
                  onChange={(e) => handleOnChange(e, 'address2')}
                />
              </Col>

              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Street Address (Third Line):</Label>
                <Input
                  type="text"
                  name="address3"
                  id="address3"
                  placeholder="Street (Third Line)"
                  value={address3}
                  onChange={(e) => handleOnChange(e, 'address3')}
                />
              </Col>
          </Row>
            
          <Row>
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">City:</Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  value={city}
                  onChange={(e) => handleOnChange(e, 'city')}
                />
                <span className="formError">{cityError}</span>
              </Col>

              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Post Code:</Label>
                <Input
                  type="text"
                  name="postcode"
                  id="postcode"
                  placeholder="Post Code"
                  value={postcode}
                  onChange={(e) => handleOnChange(e, 'postcode')}
                />
                <span className="formError">{postcodeError}</span>
              </Col>
          </Row>
            
          <Row>
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Country:</Label>
                <select 
                    className="form-control" 
                    value={country} 
                    onChange={(e) => handleOnChange(e, 'country')}
                    id="selectCountry"
                    >
                    <option value="">Select Country</option>
                      {
                        countriesData && countriesData.length >0 && 
                        countriesData.map( (item,i) => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                      ))
                      }
                    </select>
                    <span className="formError">{countryError}</span>
              </Col>
              
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">State:</Label>
                { checkStateData ?
                  <>
                <select 
                className="form-control"  
                onChange={(e) => handleOnChange(e, 'state')}
                value={state}
                id="selectState"
                >
                <option value="">Select State</option>
                {
                  stateData.length >0 && 
                  stateData.map( (item,i) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                  ))
                }
                </select>
                </>
                : <Input type="text" 
                  name="state" 
                  id="state" 
                  placeholder="state"
                  onChange={(e) => handleOnChange(e, 'state2')}
                  value={state2}
                  className="form-control"  />
                }
              </Col>
          </Row>
            
          <Row>
              <Col lg={9} style={{fontWeight: 'bold', marginTop: '30px'}}>Other Grand Lodge Contact Info</Col>
              <Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '30px'}}>
                <div style={{float: 'right', marginRight: '20%'}}>
                  <label className="switch">
                    <input type="checkbox"  onChange={handleContactInfoSwitchChange} checked={checkPrivateOtherContactInfo}/>
                    <span className="slider round"></span>
                  </label>
                  <Label for="exampleName" className="mr-2">Private?</Label>
                </div>
              </Col>
          </Row>
            
          <Row>
              <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          <Row>
              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Phone:</Label>
                <Input
                  type="text"
                  name="text"
                  id="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => handleOnChange(e, 'phone')}
                />
                <span className="formError">{phoneError}</span>
              </Col>

              <Col lg={6}>
                <Label for="exampleText" className="font-weight-bold">Email:</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => handleOnChange(e, 'email')}
                />
                <span className="formError">{emailError}</span>
              </Col>
          </Row>
            
          <Row>
              <Col lg={12}>
                <Label for="exampleText" className="font-weight-bold">Website:</Label>
                <Input
                  type="text"
                  name="website"
                  id="website"
                  placeholder="Website"
                  value={website}
                  onChange={(e) => handleOnChange(e, 'website')}
                />
                <span className="formError">{websiteError}</span>
              </Col>
          </Row>
            
          <Row>
              <Col lg={9} style={{fontWeight: 'bold', marginTop: '30px'}}>Make All Data Private</Col>
              <Col lg={3} className="switch__parent stus_grp" style={{fontWeight: 'bold', marginTop: '30px'}}>
                <div style={{float: 'right', marginRight: '20%'}}>
                  <label className="switch">
                    <input type="checkbox"  onChange={handleAllDataSwitchChange} checked={checkPrivateAllData}/>
                    <span className="slider round"></span>
                  </label>
                </div>
              </Col>
          </Row>
            
          <Row>
              <Col lg={12}><hr style={{marginTop: '0px'}} /></Col>
          </Row>
            
          { isDataPublic &&
            <Row>
              <Col lg={12}>
                <div className="alert alert-danger" role="alert">
                  <p>
			<i className="fas fa-exclamation-triangle"></i> <b><i>Are you sure that you want to make your Grand Lodge's data public?</i></b><br />
			Your Grand Lodge's information, and information about its subordinate Lodges, will be visible to anyone on the internet.
		  </p>
                  <p><input type="checkbox" onChange={visibleAllDataPublic} /><strong> Yes, I'm Sure.</strong></p>
                </div>
              </Col>
            </Row>
          }

          <Row>
              <Col lg={12}>
                <p>This switch will make all of your Grand Lodge's data private.</p>
                <p>When this switch is on, visitors will not be able to see your Grand Lodge's profile or your Lodges, although your Grand Lodge will remain in the Recognition drop-down so that visitors can see if any other visible Grand Lodge recognizes yours.</p>
                <p>When this switch is off, visitors will be able to see any data about your Grand Lodge, or its subordinate Lodges, that is not specifically marked as Private.</p>
              </Col>
          </Row> 

          </ModalBody>
          <ModalFooter>
            <Row align="right">
              <Col lg={12}>
                <Button color="secondary" onClick={props.onClick} id="closeOrganizationModal">Cancel</Button>
                <Button color="primary" style={{marginLeft:'5px'}} onClick={submitGrandLodgeHeader}>Submit</Button>
              </Col>
            </Row>
          </ModalFooter>
          { showHideLoader1 ? <CopiriLoader />: ''}
      </Modal>
    </>
  );
}
export default GrandLodgeModal;
