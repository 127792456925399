import React , { useEffect, useState } from 'react';
import {
  Col, Row
} from 'reactstrap';// eslint-disable-next-line
import GrandLodgeModal from './GrandLodgeModal';
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
const DashboardHeader = (props) => {
  const [loggedUser,setLoggedUser] = useState(false);// eslint-disable-next-line
  const [grandLodgeData,setGrandLodgeData] = useState([]);
  const [showHide,setShowHide] = useState(false);
  const [isEditable,setIsEditable] = useState(false);
  const [id,setId] = useState('');
  useEffect(() => {
    if (props.headerData.uuid) {
      setGrandLodgeData(props);
      if (props.isLogin) {
        setLoggedUser(true);
      } else {
        setLoggedUser(false);
      }
      setId(props.headerData.uuid);
      if (props.headerData.editable && props.headerData.editable===true) {
        setIsEditable(true)
      } else {
        setIsEditable(false)
      }
    }
    return () => setGrandLodgeData([]);
  // eslint-disable-next-line
  }, [props]);


  /* function to close modal */
  const closeModal = () => {
    setShowHide(!showHide);
  }
  /* function to open modal */
  const openLodgeModal = () => {
    setShowHide(!showHide);
  }

  /* function to check the communication and formed parameters*/
  function commFormed() {
    if (props.headerData.formed && props.headerData.formed !=='' && props.headerData.communication && props.headerData.communication !=='') {
      return <><Col md={6}>
          <div className="text-center first_box">
            <p style={{fontWeight: "bold"}}>Formed {props.headerData.formed}</p>
          </div>
        </Col>
        <Col md={6}>
          <div className="text-center first_box">
            <p style={{fontWeight: "bold"}}>Meets {props.headerData.communication}</p>
          </div>
        </Col> </>
    } else if (props.headerData.formed && props.headerData.formed !=='') {
      return <Col lg={12}>
        <div className="text-center first_box">
          <p style={{fontWeight: "bold"}}>Formed {props.headerData.formed} </p>
        </div>
      </Col>
    } else if (props.headerData.communication && props.headerData.communication !=='') {
      return <Col lg={12}>
        <div className="text-center first_box">
          <p style={{fontWeight: "bold"}}>Meets {props.headerData.communication} </p>
        </div>
      </Col>
    } else {
      return '';
    }
  }
  /* function to check the External Relations parameters*/
  function externalRelationsName() {
    if (props.headerData.foreignrelations && props.headerData.foreignrelations !=='') {
      if (props.headerData.foreignrelations.name ) {
        return <p><strong>External Relations: {props.headerData.foreignrelations.name}</strong></p>
      } else {

      }
    }
  }

  /* function to check the External Relations parameters*/
  function externalRelations() {
    if (props.headerData.foreignrelations && props.headerData.foreignrelations !=='') {
      if (props.headerData.foreignrelations.phone && props.headerData.foreignrelations.phone !=='' && props.headerData.foreignrelations.email && props.headerData.foreignrelations.email !=='') {
        return <p>{props.headerData.foreignrelations.phone} | {props.headerData.foreignrelations.email}</p>
      } else if (props.headerData.foreignrelations.phone && props.headerData.foreignrelations.phone !=='') {
        return <p>{props.headerData.foreignrelations.phone}</p>
      }  else if (props.headerData.foreignrelations.email && props.headerData.foreignrelations.email !=='') {
        return <p>{props.headerData.foreignrelations.email}</p>
      } else {

      }
    }
  }

  return (
    <>
    <div className="container">
    { props.headerData && props.headerData.uuid &&
      <>
      <Row>
        <Col lg={12}>
          {
            loggedUser && isEditable &&
            <Badge color="primary" className="HeaderEditBadge" onClick={() => openLodgeModal()}>
              <i className="fas fa-pen-square" onClick={() => openLodgeModal()}></i> EDIT
        	  </Badge>
          }
          <div className="text-center mb-3">
            <h2>{props.headerData.name}</h2>
          </div>
        </Col>
        {commFormed()}
      </Row>
      <Row>
        <Col md={6} className="text-center">
          <div className="scnd_box" style={{textAlign:'center'}}>
            <p className="mb-0">{props.headerData.grandmaster}</p>
            <p className="text-center"><strong>GRAND MASTER</strong></p>
          </div>
        </Col>
        <Col md={6} className="text-center">
          <div className="scnd_box" style={{textAlign:'center'}}>
            <p className="mb-0">{props.headerData.grandsecretary}</p>
            <p className="text-center"><strong>GRAND SECRETARY</strong></p>
          </div>
        </Col>
      </Row>

      <Row>
        {props.headerData.foreignrelations && props.headerData.foreignrelations !=='' &&
        <Col lg={12}>
          <div className="text-center ftr-text">
            {externalRelationsName()}
            {externalRelations()}
          </div>
        </Col>
         }
      </Row>
        

      <Row className="footr_dash">
      {props.headerData.phone ==='' && props.headerData.email ==='' && props.headerData.website ==='' ?
        <>
        { props.address1 !=='' && props.address2 !=='' && props.address3 !==''  &&
          <>
            <Col lg={12}>
              <hr />
            </Col>
            <Col lg={12}>
                <div className="addr_sec addr_sec_first text-center">
                  <ul className="text-center">
                  <li>
                    <span><i className="fas fa-university"></i></span>
                    <p>
                      {props.address1}
                    <br/>
                      {props.address2}
                    <br/>
                      {props.address3}
                    <br/>
                      {props.address4}
                    </p></li>
                  </ul>
                </div>
            </Col>
          </>
        }
        </>
      : 
          <> 
            { props.address1 !=='' && props.address2 !=='' && props.address3 !=='' ?
              <>
              <Col lg={12}>
                <hr />
              </Col>
                <Col sm={6}>
                  <div className="addr_sec addr_sec_first">
                    <ul>
                    <li>
                      <span><i className="fas fa-university"></i></span>
                      <p>
                        {props.address1}
                      <br/>
                        {props.address2}
                      <br/>
                        {props.address3}
                      <br/>
                        {props.address4}
                      </p></li>
                    </ul>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="addr_sec mb_address_section" style={{display:'flex',justifyContent:'center'}}>
                    <ul>
                    {props.headerData.phone !=='' &&
                      <li><span><i className="fas fa-phone-alt"></i></span><p>{props.headerData.phone}</p></li>
                    }
                    {props.headerData.email !=='' &&
                      <li><span><i className="fas fa-envelope"></i></span><p>{props.headerData.email}</p></li>
                    }
                    {props.headerData.website !=='' &&
                      <li><span><i className="fas fa-globe"></i></span><p><a href={props.headerData.website} target="_blank" rel="noopener noreferrer">{props.headerData.website}</a></p></li>
                    }
                    </ul>
                  </div>
                </Col>
              </>
              :
              <>
              <Col lg={12}>
                <hr />
              </Col>
                <Col sm={5}>
                </Col>
                <Col sm={7}>
                  <div className="addr_sec">
                    <ul>
                    {props.headerData.phone !=='' &&
                      <li><span><i className="fas fa-phone-alt"></i></span><p>{props.headerData.phone}</p></li>
                    }
                    {props.headerData.email !=='' &&
                      <li><span><i className="fas fa-envelope"></i></span><p>{props.headerData.email}</p></li>
                    }
                    {props.headerData.website !=='' &&
                      <li><span><i className="fas fa-globe"></i></span><p><a href={props.headerData.website} target="_blank" rel="noopener noreferrer">{props.headerData.website}</a></p></li>
                    }
                    </ul>
                  </div>
                </Col>
              </>
            }
          </>          
        }
      </Row>
      <br />
      </>
    }
      </div>
      { showHide &&
      <GrandLodgeModal showHide={showHide} onClick={closeModal} updateModal={closeModal} id={id} grandLodgeData={grandLodgeData} updateGrandLodgeData={props.updateGrandLodgeData} />
      }
    </>
  );
};

export default DashboardHeader;