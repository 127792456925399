/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import config from '../config/config';// eslint-disable-next-line
import {copiriAxiosPost,sessionLogout,copiriPortalRequest} from '../api/api';
import {withRouter} from 'react-router-dom'; // eslint-disable-next-line
import  { Redirect } from 'react-router-dom';// eslint-disable-next-line
import { toast } from 'react-toastify';
class OrgDropDown extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      country: '30169',
      admin_dropdown_data: [],
      is_login : true,
      showHideLoader: false,

    };
  }
  changeSelectBox = event => {
    this.setState({country: event.target.value});
    this.setState({
      //showHideLoader: true,
    });
    localStorage.setItem('admin_dropdown_value', event.target.value);
    localStorage.setItem('selected_org_id', event.target.value);
    localStorage.setItem('changeDropDown',true);
    //window.location.reload(true);
  }
  componentDidMount(){
    copiriPortalRequest(config.elol_api_url + '/grandlodges', config.app_version, 'get').then(response => {
      if (response.data.grandlodges.length > 0 ) {
        this.setState({
          admin_dropdown_data:response.data.grandlodges
        });
        this.setState({
          country:localStorage.getItem('selected_org_id')
        });
      }
    }).catch(error=>{});
  }

  
  render(){
    // eslint-disable-next-line
    const { admin_dropdown_data,is_login,country,showHideLoader} = this.state;
    if (admin_dropdown_data.length>0) {
      return (
        <>
        {showHideLoader ?
          <div className="loader"></div>
        : ''}
          <li className="nav-item">
            <div className="d-flex align-items-center">
              <span className="nav-link-icon">
                <i className="fas fa-map ml-1"></i>
              </span>
              <select className="form-control" value={country} onChange={this.changeSelectBox} name="admin_dropdown" id="admin_dropdown" style={{width:'150px',marginLeft:'0',color:'#5e6e82',fontSize:'.875rem',fontWeight:'500',padding:'0'}} >
                {
                  admin_dropdown_data.length >0 && 
                  admin_dropdown_data.map( (item,i) => (
                    <option value={item.uuid} key={i} text={item.name} id={item.uuid} className="nav-link-text">{item.name}</option>
                  ))
                }
                </select>
            </div>
          </li>
        </>
      );
    }else{
      return (
      <></>
      );
    }
  }
}

export default withRouter(OrgDropDown);
